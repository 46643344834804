import React from 'react'
import {
  Box, Fade, Grow, List, ListItem, Popper, Typography,
} from '@mui/material'
import { useNavigate, useLocation, useMatch } from 'react-router-dom'
import styled from 'styled-components'
import { SubNavigationItem } from '../../objects/navigationItem'

interface NavigationItemProps {
  defaultIcon: JSX.Element
  hoverIcon: JSX.Element
  selectedIcon: JSX.Element
  redirectPath: string
  highlightedColor: string
  itemTitle: string
  subTab: SubNavigationItem[]
}

const NavigationContainerBox = styled(Box)`
  &:hover {
    cursor: pointer
  }
`

function NavigationItem(props: NavigationItemProps) {
  const {
    redirectPath, highlightedColor,
    itemTitle, subTab,
  } = props
  const [hover, setHover] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleHoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleHoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'transitions-popper' : undefined
  const handleHover = (hoverState: boolean) => {
    setHover(hoverState)
  }
  const navigate = useNavigate()
  const location = useLocation()
  const routeMatch = useMatch(redirectPath)
  // let navigationIcon = <></>;
  let titleBackgroundColor = '#FFFFFF00'
  let titleComponent = (
    <Typography
      variant="body1"
      noWrap
    >
      <span>
        {itemTitle}
      </span>
    </Typography>
  )
  if (routeMatch != null) {
    // navigationIcon = selectedIcon;
    titleBackgroundColor = highlightedColor
    titleComponent = (
      <Typography
        variant="body1"
        noWrap
      >
        <span>
          {itemTitle}
        </span>
      </Typography>
    )
  }
  else if (hover) {
    // navigationIcon = hoverIcon;
    titleBackgroundColor = highlightedColor
  }
  else {
    // navigationIcon = defaultIcon;
  }

  return (
    <NavigationContainerBox
      display="flex"
      flex="1"
      zIndex={1}
      flexDirection="column"
      onMouseEnter={(e) => {
        handleHover(true)
        handleHoverOpen(e)
      }}
      onMouseLeave={() => {
        handleHover(false)
        handleHoverClose()
      }}
      ml={2}
      mr={2}
    >
      <Box flex="1" display="flex" onClick={() => navigate(`${redirectPath}`)}>
        <Box
          flex="1"
          justifyContent="center"
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          { routeMatch || hover
            ? (
                <Box
                  display="flex"
                  height="2px"
                  width="100%"
                />
              )
            : null}
          <Box flex={1} display="flex" justifyContent="center" alignItems="center">
            {titleComponent}
          </Box>
          { routeMatch || hover
            ? (
                <Grow in timeout={500}>
                  <Box
                    style={{ backgroundColor: titleBackgroundColor }}
                    display="flex"
                    height="2px"
                    width="100%"
                  />
                </Grow>
              )
            : null}

        </Box>
      </Box>
      {subTab.length > 0
        ? (
            <Popper id={id} open={open} anchorEl={anchorEl} transition placement="bottom-start" style={{ zIndex: 1 }}>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Box style={{ backgroundColor: '#FFFFFF00' }}>
                    <List>
                      {subTab.map(subItem => (
                        <ListItem
                          button
                          key={subItem.subtitle}
                          onClick={() => {
                            navigate(`${subItem.redirectPath}${location.search}`)
                            handleHoverClose()
                          }}
                        >
                          <Box pb={1} pt={1} pr={2} pl={2}>
                            <Typography
                              variant="body1"
                              noWrap
                            >
                              <span>
                                {subItem.subtitle}
                              </span>
                            </Typography>
                          </Box>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Fade>
              )}
            </Popper>
          )
        : null}
    </NavigationContainerBox>
  )
}

export default NavigationItem
