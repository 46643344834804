import { useAuth0 } from '@auth0/auth0-react'
import {
  Box, Button, Typography, useMediaQuery, useTheme,
} from '@mui/material'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'

function LogoutButton() {
  const { logout, user } = useAuth0()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box
      display="flex"
      flexDirection={isMobile ? 'column' : 'row'}
      alignItems={isMobile ? 'center' : 'center'}
      justifyContent="center"
      flex={1}
      pt={isMobile ? 2 : 0}
      pb={isMobile ? 2 : 0}
      style={{ backgroundColor: isMobile ? theme.palette.primary.light : '' }}
    >
      <Box display="flex" flex={1} justifyContent="flex-end">
        {
          user?.name && (
            <Box display="flex" alignItems="center" mr={isMobile ? 1 : 2}>
              <Typography variant="body1" style={{ color: theme.palette.common.white }}>
                Hi
                {' '}
                {user.name}
              </Typography>
            </Box>
          )
        }

        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Button
            style={{
              textDecoration: 'none', color: 'white', display: 'flex', justifyContent: 'center',
            }}
            onClick={() => {
              void logout({ logoutParams: { returnTo: window.location.origin } })
            }}
            startIcon={<ExitToAppIcon style={{ color: 'white' }} />}
          >
            <Typography variant="button">
              Log Out
            </Typography>
          </Button>
        </Box>

      </Box>

    </Box>

  )
}

export default LogoutButton
