import {
  Box, TableContainer, Typography, Table, Paper, TableHead,
  TableRow, TableCell, TableBody, Grid,
  IconButton, useTheme, Chip, Tooltip,
} from '@mui/material'
import {
  useCallback, useEffect, useMemo, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import CancelIcon from '@mui/icons-material/Cancel'
import ProgressIcon from '@mui/icons-material/DonutLarge'
import PauseCircleOutlineIcon from '@mui/icons-material/DoDisturb'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import Stack from '@mui/material/Stack'
import ReducerRootState from '../../objects/reducerRootState'
import { fetchSessions, selectSession } from '../../services/ResultsService'
import LoadingView from '../LoadingView'
import PageLayout from '../PageLayout'

import Flag from '../../assets/flag.png'
import alarm from '../../assets/giphy.gif'
import SessionsSearchBox from './SessionsSearchBox'
import '../../styles/sessionDetails.css'
import { RiskType, Session } from '../../objects/sessionsDto'
import { selectUserScopes } from '../../services/AccountService/selectors'

const ClickableTableRow = styled(TableRow)`
  &:hover {
    cursor: pointer;
  }
`

interface StatusFilter {
  status: string
  label: string
}

function SessionListingPage(): JSX.Element {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()

  const urlParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const page = parseInt(urlParams.get('page') ?? '1', 10)
  const setPage = useCallback((newPage) => {
    navigate(`${location.pathname}?page=${newPage}`)
  }, [location.pathname, page])
  const [initialLoad, setInitialLoad] = useState(true)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [search, setSearch] = useState(() => urlParams.get('search') ?? '')
  const [statusFilter, setStatusFilter] = useState<StatusFilter>({ status: '', label: '' })
  const [statusFilterSelected, setStatusFilterSelected] = useState(false)
  const { sessions, totalSessions, previouslySelectedSession } = useSelector((state: ReducerRootState) => state.results)
  const isLoadingSessions = useSelector((state: ReducerRootState) => state.results?.isLoadingSessions)
  const tenantConfig = useSelector((state: ReducerRootState) => state.config.config)
  useEffect(() => {
    dispatch(fetchSessions(page, rowsPerPage, search, statusFilter.status))
    setInitialLoad(false)
  }, [])

  const onRowClicked = (sessionId) => {
    dispatch(selectSession(sessionId))
    navigate(`/sessions/${sessionId}?listing_page=${page}&listing_search=${search}`)
  }

  const navigateRight = () => {
    const newPage = page + 1
    setPage(newPage)
    dispatch(fetchSessions(newPage, rowsPerPage, search, statusFilter.status))
  }

  const navigateLeft = () => {
    const newPage = page - 1
    setPage(newPage)
    dispatch(fetchSessions(newPage, rowsPerPage, search, statusFilter.status))
  }
  const userScopes = useSelector(selectUserScopes)
  const renderDetailsMatch = (detailStatus) => {
    switch (detailStatus) {
      case 'PARTIAL_MATCH':
        return 'P/Match'
      case 'NO_MATCH':
        return 'No Match'
      case 'MATCHED':
        return 'Match'
      case 'IN_PROGRESS':
        return 'In Progress'
      default:
        return 'Not Started'
    }
  }
  const renderStageStatus = (stageStatus) => {
    switch (stageStatus) {
      case 'NOT_STARTED':
      case 'NOT_COMPLETED':
        return '#5D5B5B'
      case 'IN_PROGRESS':
        return '#ffad33'
      case 'MATCHED':
      case 'LIVENESS_PROVEN':
        return '#006600'
      case 'LIVENESS_NOT_PROVEN_USER_NEEDS_TO_RETRY':
      case 'NO_MATCH':
      case 'FAILURE':
        return '#B00000'
      case 'PARTIAL_MATCH':
        return '#ffbb33'
      default:
        return ''
    }
  }
  const renderMainResultStatus = (mainResult) => {
    switch (mainResult) {
      case 'NOT_STARTED':
        return '#5D5B5B'
      case 'IN_PROGRESS':
        return '#ffad33'
      case 'MATCHED':
      case 'SUCCESS':
      case 'LIVENESS_PROVEN':
        return '#006600'
      case 'FAILURE':
        return '#B00000'
      case 'PARTIAL_SUCCESS':
        return '#ffbb33'
      default:
        return ''
    }
  }

  const renderRowColor = (session: Session) => {
    if (previouslySelectedSession === session.sessionId && !session.fraudResult?.matched) {
      return theme.palette.grey[800]
    }
    if (session.fraudResult && session.fraudResult.matched) {
      return 'rgb(206 50 50 / 62%)'
    }
    return ''
  }
  const lowerLimit = 1 + ((page - 1) * rowsPerPage)
  let upperLimit = page * rowsPerPage
  if (upperLimit > totalSessions) {
    upperLimit = totalSessions
  }
  const maxNumberOfPages = (totalSessions % rowsPerPage > 0) ? Math.floor((totalSessions / rowsPerPage) + 1) : Math.floor(totalSessions / rowsPerPage)
  const resultsStepIcons = (stageStatus = {}) => {
    switch (stageStatus) {
      case 'NOT_STARTED':
      case 'NOT_COMPLETED':
        return <PauseCircleOutlineIcon style={{ color: 'white' }} />
      case 'IN_PROGRESS':
        return <PlayCircleIcon style={{ color: 'white' }} />
      case 'MATCHED':
      case 'LIVENESS_PROVEN':
        return <CheckCircleIcon style={{ color: 'white' }} />
      case 'LIVENESS_NOT_PROVEN_USER_NEEDS_TO_RETRY':
      case 'NO_MATCH':
      case 'FAILURE':
        return <CancelIcon style={{ color: 'white' }} />
      case 'PARTIAL_MATCH':
        return <ProgressIcon style={{ color: 'white' }} />
      default:
        return <CheckCircleIcon style={{ color: 'white' }} />
    }
  }
  const mainResultCustomisation = (mainResult) => {
    switch (mainResult) {
      case 'PARTIAL_SUCCESS':
        return 'Partial Match'
      case 'SUCCESS':
        return 'Success'
      case 'FAILURE':
        return 'Failure'
      case 'IN_PROGRESS':
        return 'In Progress'
      default:
        return 'Not Started'
    }
  }
  const countRisks = (riskTypes, registrationPlace?: string) => {
    const muteRisks = ['UNEXPECTED_MEDIA']
    if (registrationPlace === 'QLD') muteRisks.push('TAMPERING')
    const riskCounts = riskTypes.filter(item => !(muteRisks.includes(item.riskType)))
    if (riskCounts.length > 0) return riskCounts.length
    return null
  }
  const renderRiskTitle = (types: RiskType, registrationPlace?: string) => {
    switch (types) {
      case 'HIGH_IP_FRAUD_SCORE':
        return (
          <span>
            High IP fraud rating
            {' '}
            <br />
          </span>
        )
      case 'MAXIMUM_GEO_DISTANCE':
        return (
          <span>
            Long distance risk
            {' '}
            <br />
          </span>
        )

      case 'SUSPICIOUS_IP':
        return (
          <span>
            VPN/Proxy detected
            {' '}
            <br />
          </span>
        )
      case 'DIGITAL_ID': return (
        <span>
          Digital ID
          {' '}
          <br />
        </span>
      )
      case 'UNIDENTIFIED_TEMPLATE':
        return (
          <span>
            Unknown ID type presented
            <br />
          </span>
        )
      case 'UNEXPECTED_MEDIA':
        return null
      case 'TAMPERING':
        return registrationPlace === 'QLD'
          ? null
          : (

              <span>
                Possible ID tampering
                {' '}
                <br />
              </span>
            )

      case 'UNSPECIFIED_LOCATION':
        return (
          <span>
            Location Not Provided
            {' '}
            <br />
          </span>
        )
      case 'MANIPULATION':
        return (
          <span>
            Session Manipulation
            {' '}
            <br />
          </span>
        )
      default: return null
    }
  }

  let content = (
    <>
      <Box mt={4} display="flex" flexDirection="column" alignItems="flex-start">
        <Typography style={{ textAlign: 'center', color: ' white ', fontSize: '22px' }}>
          Sessions
        </Typography>
      </Box>
      <Box mt={2} mb={4}>

        <Box mb={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <SessionsSearchBox
                value={search}
                onChange={(updatedSearch) => { setSearch(updatedSearch) }}
                onSearchSubmit={() => { dispatch(fetchSessions(page, rowsPerPage, search, statusFilter.status)) }}
              />
            </Grid>
            {/* <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {
                statusFilterSelected
                  ? (
                    <Chip
                      label={statusFilter.label}
                      onDelete={() => {
                        setStatusFilterSelected(false);
                        setStatusFilter({ status: '', label: '' });
                        dispatch(fetchSessions(page, rowsPerPage, search, ''));
                      }}
                      color="primary"
                      style={{ backgroundColor: 'gray' }}
                      deleteIcon={<CancelIcon style={{ color: 'white' }} />}
                    />
                  )
                  : (
                    <FilterButton name="Status">
                      <>
                        <Box mb={1}>
                          <Typography variant="body1"><b>Search by status:</b></Typography>
                        </Box>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="status"
                            name="status"
                            value={statusFilter.status}
                            onChange={(event) => {
                              const newStatus = (event.target as HTMLInputElement).value;
                              let label = '';
                              if (newStatus === 'IN_PROGRESS') {
                                label = 'In Progress';
                              } if (newStatus === 'ABANDONED') {
                                label = 'Abandoned';
                              } else if (newStatus === 'COMPLETED') {
                                label = 'Complete';
                              }
                              setStatusFilter({ status: newStatus, label });
                            }}
                          >
                            <FormControlLabel value="IN_PROGRESS" control={<Radio color="primary" />} label="In progress" />
                            <FormControlLabel value="COMPLETED" control={<Radio color="primary" />} label="Complete" />
                            <FormControlLabel value="ABANDONED" control={<Radio color="primary" />} label="Abandoned" />
                          </RadioGroup>
                        </FormControl>
                        <Box mt={1}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              const newPage = 1;
                              setPage(newPage);
                              dispatch(fetchSessions(newPage, rowsPerPage, search, statusFilter.status));
                              setStatusFilterSelected(true);
                            }}
                            fullWidth
                            size="large"
                            disableElevation
                            style={{ color: 'white' }}
                          >
                            Search
                          </Button>
                        </Box>
                      </>
                    </FilterButton>
                  )
              }

            </Grid> */}

            {/* <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <FilterButton name="Date">
                  <div>Date filter</div>
                </FilterButton>
              </Grid> */}
          </Grid>
        </Box>

        <Paper style={{ backgroundColor: 'transparent' }}>
          <TableContainer>
            <Table>
              <TableHead style={{ color: '#fff', backgroundColor: '#1F2A37' }}>
                <TableRow>
                  <TableCell>
                    <Typography />
                  </TableCell>
                  <TableCell>
                    <Typography style={{ fontWeight: 'bold', color: 'white' }}>
                      User
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography style={{ fontWeight: 'bold', color: 'white' }}>
                      Timestamp
                    </Typography>
                  </TableCell>
                  {/* <TableCell>
                    <Typography style={{ fontWeight: 'bold', color: 'white' }}>
                      Session ID
                    </Typography>
                  </TableCell> */}
                  <TableCell>
                    <Typography style={{ fontWeight: 'bold', color: 'white' }}>
                      Main Result
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography style={{
                      fontWeight: 'bold', color: 'white', display: 'flex',
                    }}
                    >
                      Results
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography style={{ fontWeight: 'bold', color: 'white' }}>
                      Originator
                    </Typography>
                  </TableCell>
                  {userScopes.indexOf('read:session_risks') > 0
                  && (
                    <TableCell>
                      <Typography style={{ fontWeight: 'bold', color: 'white' }}>
                        Risk
                      </Typography>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody style={{ backgroundColor: 'transparent' }}>
                {sessions.map(session => (
                  <ClickableTableRow
                    hover
                    role="checkbox"
                    key={session.sessionId}
                    onClick={() => { onRowClicked(session.sessionId) }}
                    style={{ backgroundColor: `${renderRowColor(session)}` }}
                  >
                    <TableCell className="session-table-cell">
                      {session.fraudResult && session.fraudResult.matched
                        ? (
                            <img
                              alt="Possible Fraud"
                              src={alarm}
                              width="38px"
                              title="Face from watch list detected here "
                            />
                          )
                        : (
                            <> </>
                          )}
                    </TableCell>
                    <TableCell className="session-table-cell">
                      <Typography>
                        {session.userInfo && `${session.userInfo.name?.first}
                         ${session.userInfo.name?.middle ? session.userInfo.name?.middle : ''}
                          ${session.userInfo.name?.last}`}
                      </Typography>
                    </TableCell>
                    <TableCell className="session-table-cell">
                      <Typography>
                        {new Date(session.timestamp).toLocaleDateString('en-AU', { day: 'numeric', month: 'numeric', year: 'numeric' })}

                        {' '}
                        {new Date(session.timestamp).toLocaleTimeString('en-AU')}
                      </Typography>
                    </TableCell>
                    {/* <TableCell className="session-table-cell">
                      <Typography>
                        {session.sessionId}
                      </Typography>
                    </TableCell> */}
                    <TableCell className="session-table-cell">
                      <Typography style={{
                        borderBottomColor: `${renderMainResultStatus(session.results?.business?.mainResult)}`,
                        borderBottomStyle: 'solid',
                        borderBottomWidth: '1px',
                      }}
                      >
                        {/* <Button startIcon={resultsStepIcons(session.results?.business?.mainResult)} /> */}
                        {mainResultCustomisation(session.results?.business?.mainResult)}
                        {/* <Chip
                          title={session.results?.business?.mainResult}
                          label={session.results?.business?.mainResult}
                          icon={resultsStepIcons(session.results?.business?.livenessStatus)}
                          style={{
                            backgroundColor: `${renderMainResultStatus(session.results?.business?.mainResult)}`,
                            marginRight: '3px',
                            color: 'white',
                          }}
                        /> */}
                      </Typography>
                    </TableCell>
                    <TableCell className="session-table-cell">
                      <Stack direction="row" spacing={1}>
                        <Chip
                          title={session.results.liveness}
                          label="Liveness"
                          icon={resultsStepIcons(session.results?.business?.livenessStatus)}
                          style={{
                            backgroundColor: `${renderStageStatus(session.results?.business?.livenessStatus)}`,
                            marginRight: '3px',
                            color: 'white',
                            width: '5.5rem',
                          }}
                        />
                        <Chip
                          title={session.results.idScanMatchLevel}
                          label={(session.results.idScanMatchLevel)}
                          icon={resultsStepIcons(session.results?.business?.facematchStatus)}
                          style={{
                            backgroundColor: `${renderStageStatus(session.results?.business?.facematchStatus)}`,
                            marginRight: '3px',
                            color: 'white',
                            width: '5.5rem',
                          }}
                        />
                        <Chip
                          title={session.results.detailsMatch}
                          label={renderDetailsMatch(session.results.business.detailsmatchStatus)}
                          icon={resultsStepIcons(session.results.business.detailsmatchStatus)}
                          style={{
                            backgroundColor: `${renderStageStatus(session.results?.business?.detailsmatchStatus)}`,
                            color: 'white',
                            marginRight: '3px',
                            width: '5.5rem',
                          }}
                        />
                        <Chip
                          title={session.results.dvs}
                          icon={resultsStepIcons(session.results.business.dvsStatus)}
                          label="DVS"
                          style={{ backgroundColor: `${renderStageStatus(session.results?.business?.dvsStatus)}`, color: 'white' }}
                        />
                      </Stack>

                    </TableCell>
                    <TableCell
                      className="session-table-cell"
                    >
                      <Typography>
                        {session.originator }
                      </Typography>
                    </TableCell>
                    {userScopes.indexOf('read:session_risks') > 0
                    && (
                      <TableCell
                        className="session-table-cell"
                      >
                        <Typography className="risk-section">
                          {(session.results?.riskEvents?.length > 0

                            ? (
                                <Box style={{ display: 'flex' }}>
                                  <Typography style={{ fontSize: '1rem', fontWeight: 'bold' }}>
                                    {countRisks(session.results.riskEvents, session.userInfo?.idList?.[0]?.registrationPlace)}

                                  </Typography>
                                  {countRisks(session.results.riskEvents) && countRisks(session.results.riskEvents) > 0
                                    ? (
                                        <Tooltip title={(
                                          <Typography style={{ fontSize: '12px' }}>
                                            {session.results?.riskEvents.map(risk => renderRiskTitle(
                                              risk.riskType,
                                              session.userInfo?.idList?.[0]?.registrationPlace,
                                            ))}
                                          </Typography>
                                        )}
                                        >
                                          <span>
                                            <img src={Flag} alt="Red" width={22} />
                                          </span>
                                        </Tooltip>
                                      )
                                    : null}
                                </Box>

                              )
                            : null)}
                        </Typography>
                      </TableCell>
                    )}

                  </ClickableTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box display="flex" alignItems="center" justifyContent="flex-end" p={2} color={theme.palette.common.white}>
            <Box mr={1} ml={1}>
              <Typography variant="body1">
                {lowerLimit}
                {' '}
                -
                {' '}
                {upperLimit}
                {' '}
                of
                {' '}
                {totalSessions}
              </Typography>
            </Box>
            <IconButton disabled={page === 1} color="inherit" aria-label="navigateLeft" onClick={() => { navigateLeft() }}>
              <ChevronLeftIcon style={{ color: page === 1 ? theme.palette.grey[300] : theme.palette.primary.main }} />
            </IconButton>
            <IconButton disabled={page === maxNumberOfPages} color="inherit" aria-label="navigateRight" onClick={() => { navigateRight() }}>
              <ChevronRightIcon style={{ color: page === maxNumberOfPages ? theme.palette.grey[300] : theme.palette.primary.main }} />
            </IconButton>
          </Box>
        </Paper>
      </Box>
    </>
  )

  if (initialLoad || isLoadingSessions) {
    content = <LoadingView />
  }

  return (
    <PageLayout>
      {content}
    </PageLayout>
  )
}

export default SessionListingPage
