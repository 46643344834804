/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box, Card, CardContent, Typography, CardMedia, Divider,
} from '@mui/material'

import moment from 'moment'
import { UserInfo } from '../../../objects/userInfo'
import { ModifiedOCRResults, IdScan } from '../../../objects/sessionResultsDto'
import ocrImg from '../../../assets/driving-license.png'
import userImg from '../../../assets/userImg.png'
import confirmedUserImg from '../../../assets/confirmation1.png'
import '../../../styles/sessionDetails.css'

interface UserInfoTabProps {
  userInfo: UserInfo | undefined
  modifiedOCRResults: ModifiedOCRResults[] | undefined
  originalOCRResult: UserInfo | undefined
  // type: 'ORIGINAL' | 'MODIFIED'
}

function UserInfoTab(props: UserInfoTabProps) {
  const { userInfo, modifiedOCRResults, originalOCRResult } = props
  const formattedDOB = moment(userInfo?.dateOfBirth).format('DD/MM/YYYY')
  const cardNumberStates = `${import.meta.env.VITE_CARD_NUMBER_STATES}`.split(',')
  return (
    <Box mt={2} style={{ display: 'flex', flexDirection: 'row' }}>
      {(typeof userInfo === 'undefined')
      && (
        <Box mt={6}>
          <Typography variant="body1">
            There is no user info for this session
          </Typography>
        </Box>
      )}
      <Box className="confirm-user-header">
        {(userInfo) && (
          <Card className="confirm-user-layout">
            <CardContent>
              <Box mb={2}>
                <CardMedia
                  component="img"
                  style={{
                    display: 'inline',
                    width: '3rem',
                  }}
                  image={userImg}
                />
              </Box>
              <Box>
                <Box mb={2}>
                  <Typography variant="body1"><b>Original User Info</b></Typography>
                </Box>
                <Typography variant="body1">{`Name: ${userInfo?.name?.first} ${userInfo?.name?.last}`}</Typography>
                <Typography variant="body1">{`Date of Birth: ${formattedDOB}`}</Typography>
                {userInfo?.idList.map((id, idIndex) => (
                  <Box key={id.idNumber} mt={2}>
                    <Typography variant="body1"><b>ID</b></Typography>
                    <Typography variant="body1">{`ID Type: ${id?.idType}`}</Typography>
                    <Typography variant="body1">{`ID Number: ${id?.idNumber}`}</Typography>
                    {cardNumberStates.includes(id.registrationPlace)
                      ? (<Typography variant="body1">{`Card Number: ${id?.cardNumber}`}</Typography>)
                      : <Typography variant="body1">Card Number: N/A</Typography>}
                    <Typography variant="body1">{`Registration Place: ${id?.registrationPlace}`}</Typography>
                  </Box>
                ))}
                {userInfo.addressList.map((address, addressIndex) => (
                  <Box key={address?.firstLine} mt={2}>
                    <Typography variant="body1"><b>{`Address ${addressIndex + 1}:`}</b></Typography>
                    <Typography variant="body1">{`${address.firstLine}`}</Typography>
                    <Typography variant="body1">{`${address.suburb} ${address.state} ${address.postcode} ${address.countryCode}`}</Typography>
                  </Box>
                ))}
              </Box>
            </CardContent>
          </Card>
        )}
        {(typeof originalOCRResult === 'undefined' || originalOCRResult == null)
        && (
          <Box mt={6} ml={2}>
            <Typography variant="body1">
              There is no OCR Result info for this session
            </Typography>
          </Box>
        )}
        {(typeof originalOCRResult === 'string' && (originalOCRResult === 'NOT_AUSTRALIAN_PHOTO_ID' || originalOCRResult === 'ID_INVALID'))
        && (
          <Box mt={6} ml={2}>
            <Typography variant="body1">
              ID cannot be verified for this session
            </Typography>
          </Box>
        )}
        {(originalOCRResult && typeof originalOCRResult !== 'string') && (
          <Card className="ocr-user-layout">
            <CardContent>
              <Box mb={2}>
                <CardMedia
                  component="img"
                  style={{
                    display: 'inline',
                    width: '3rem',
                  }}
                  image={ocrImg}
                />
              </Box>
              <Box>
                <Box mb={2}>
                  <Typography variant="body1"><b>OCR Result</b></Typography>
                </Box>
                <Typography variant="body1" style={{ color: 'white' }}>{`Name: ${originalOCRResult.name?.first} ${originalOCRResult.name.last}`}</Typography>
                <Typography variant="body1" style={{ color: 'white' }}>{`Date of Birth: ${originalOCRResult?.dateOfBirth}`}</Typography>
                {originalOCRResult?.idList?.map((id, idIndex) => (
                  <Box key={id.idNumber} mt={2}>
                    <Typography variant="body1"><b>ID</b></Typography>
                    <Typography variant="body1">{`ID Type: ${id?.idType}`}</Typography>
                    <Typography variant="body1">{`ID Number: ${id?.idNumber}`}</Typography>
                    {cardNumberStates.includes(id.registrationPlace)
                      ? (<Typography variant="body1">{`Card Number: ${id?.cardNumber}`}</Typography>)
                      : <Typography variant="body1">Card Number: N/A</Typography>}
                    <Typography variant="body1">{`Registration Place: ${id?.registrationPlace}`}</Typography>
                  </Box>
                ))}
                {originalOCRResult?.addressList.map((address, addressIndex) => (
                  <Box key={address.firstLine} mt={2}>
                    <Typography variant="body1"><b>{`Address ${addressIndex + 1}:`}</b></Typography>
                    <Typography variant="body1">{`${address.firstLine}`}</Typography>
                    <Typography variant="body1">{`${address.suburb} ${address.state} ${address.postcode} ${address.countryCode}`}</Typography>
                  </Box>
                ))}
              </Box>
            </CardContent>
          </Card>
        )}
        {(typeof modifiedOCRResults === 'undefined' || modifiedOCRResults.length === 0)
        && (
          <Box mt={6} ml={2}>
            <Typography variant="body1">
              There is no Confirmed info for this session
            </Typography>
          </Box>
        )}
        {modifiedOCRResults && modifiedOCRResults.length > 0 && (
          <Card
            className="container"
            style={{
              display: 'flex', flexDirection: 'column', width: '33%', marginLeft: '1rem', backgroundColor: '#88b2bf24',
            }}
          >
            <CardContent>
              <Box mb={2}>
                <CardMedia
                  component="img"
                  style={{
                    display: 'inline',
                    width: '3rem',
                  }}
                  image={confirmedUserImg}
                />
              </Box>
              <Box>
                {modifiedOCRResults.map((ocr, index) => (
                  <Box mt={2}>
                    <Box mb={2}>
                      <Typography variant="body1">
                        <b>
                          Confirmed User Info
                          {' '}
                          {modifiedOCRResults.length - index}
                        </b>
                      </Typography>
                    </Box>
                    <Typography variant="body1">{`Name: ${ocr?.ocrResults.name.first} ${ocr.ocrResults.name.last}`}</Typography>
                    <Typography variant="body1">{`Date of Birth: ${moment(ocr?.ocrResults.dateOfBirth).format('DD/MM/YYYY')}`}</Typography>
                    {ocr.ocrResults.idList.map((id, idIndex) => (
                      <Box key={id.idNumber} mt={2}>
                        <Typography variant="body1"><b>ID</b></Typography>
                        <Typography variant="body1">{`ID Type: ${id?.idType}`}</Typography>
                        <Typography variant="body1">{`ID Number: ${id?.idNumber}`}</Typography>
                        {cardNumberStates.includes(id.registrationPlace)
                          ? (<Typography variant="body1">{`Card Number: ${id?.cardNumber}`}</Typography>)
                          : <Typography variant="body1">Card Number: N/A</Typography>}
                        <Box mb={2}>
                          {' '}
                          <Typography variant="body1">{`Registration Place: ${id?.registrationPlace}`}</Typography>
                        </Box>
                      </Box>
                    ))}
                    {ocr.ocrResults.addressList.map((address, addressIndex) => (
                      <Box key={address.firstLine} mt={2}>
                        <Typography variant="body1"><b>{`Address ${addressIndex + 1}:`}</b></Typography>
                        <Typography variant="body1">{`${address.firstLine}`}</Typography>
                        <Typography variant="body1">{`${address.suburb} ${address.state} ${address.postcode} ${address.countryCode}`}</Typography>
                      </Box>
                    ))}
                    <Divider className="user-divider" />
                  </Box>
                ))}
              </Box>
            </CardContent>
          </Card>

        )}
      </Box>
    </Box>
  )
}

export default UserInfoTab
