import UserSummaryDto from './userSummaryDto'
import UserDetailsDto from './userDetailsDto'

interface UserManagementState {
  requestingUsersState: UserRequestState
  users: UserSummaryDto[]

  requestingUserState: UserRequestState
  selectedUserId: string
  selectedUserDetails: UserDetailsDto | null
}

export enum UserRequestState {
  NONE,
  REQUESTING,
  FULFILLED,
  ERROR,
}

export default UserManagementState
