import ReducerRootState from '../../objects/reducerRootState'

// eslint-disable-next-line import/prefer-default-export
export function selectUserScopes(state: ReducerRootState): string[] {
  const { loggedIn, accessToken } = state.account

  if (!loggedIn) {
    return []
  }

  if (accessToken.length === 0) {
    return []
  }

  const parts = accessToken.split('.')
  if (parts.length !== 3) {
    return []
  }

  let scopeString

  try {
    scopeString = JSON.parse(atob(parts[1])).scope
  }
  catch (e) {
    return []
  }

  if (typeof scopeString !== 'string') {
    return []
  }

  const scopes = scopeString.split(' ')

  return scopes
}
