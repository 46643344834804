import UserManagementState, { UserRequestState } from '../../objects/userManagementState'
import { types } from './actions'

const initialState: UserManagementState = {
  requestingUsersState: UserRequestState.NONE,
  users: [],

  requestingUserState: UserRequestState.NONE,
  selectedUserId: '',
  selectedUserDetails: null,
}

export default function userManagementReducer(state: UserManagementState = initialState, action): UserManagementState {
  switch (action.type) {
    case types.REQUEST_USERS:
      return { ...state, requestingUsersState: UserRequestState.REQUESTING }
    case types.RECEIVE_USERS:
      return { ...state, requestingUsersState: UserRequestState.FULFILLED, users: action.users }
    case types.RECEIVE_USERS_ERROR:
      return { ...state, requestingUsersState: UserRequestState.ERROR }

    case types.REQUEST_USER:
      return {
        ...state,
        requestingUserState: UserRequestState.REQUESTING,
        selectedUserId: action.userId,
        selectedUserDetails: null,
      }
    case types.RECEIVE_USER:
      if (state.selectedUserId !== action.userId) {
        return state
      }
      return {
        ...state,
        requestingUserState: UserRequestState.FULFILLED,
        selectedUserDetails: action.user,
      }
    case types.RECEIVE_USER_ERROR:
      if (state.selectedUserId !== action.userId) {
        return state
      }
      return { ...state, requestingUserState: UserRequestState.ERROR }
    case types.CLEAR_SELECTED_USER:
      if (state.selectedUserId !== action.userId) {
        return state
      }
      return {
        ...state,
        requestingUserState: UserRequestState.NONE,
        selectedUserId: '',
        selectedUserDetails: null,
      }

    default:
      return state
  }
}
