export const getTenantForDevelopment = (urlParams: URLSearchParams): string => {
  const tenant = urlParams.get('tenant')
  return tenant || 'bryk'
}

export const getTenantForProduction = (): string => {
  const hostParts = window.location.host.split('.')
  const tenantRoot = hostParts.slice(-3).join('.')

  switch (tenantRoot) {
    case 'nowfinance.brykid.com': return 'nowfinance'
    case 'dev.brykid.com': return 'bryk'
    default: return hostParts[1]
  }
}
