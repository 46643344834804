import EnvironmentState from '../../objects/environmentState'
import { types } from './actions'

const initialState: EnvironmentState = {
  nodeEnv: '',
  environment: '',
  tenant: '',
  customerGroup: '',
  customerGroupConfig: {
    logo: '',
    backgroundColor: '',
  },
  completedInitialSetup: false,
}

export default function environmentReducer(state: EnvironmentState = initialState, action): EnvironmentState {
  switch (action.type) {
    case types.INITIAL_SETUP:
      return {
        ...state,
        nodeEnv: action.nodeEnv,
        environment: action.environment,
        tenant: action.tenant,
      }
    case types.SET_NODE_ENV:
      return { ...state, nodeEnv: action.nodeEnv }
    case types.SET_ENVIRONMENT:
      return { ...state, environment: action.environment }
    case types.SET_TENANT:
      return { ...state, tenant: action.tenant }
    case types.COMPLETE_INITIAL_SETUP:
      return { ...state, completedInitialSetup: true }
    case types.SET_CUSTOMER_GROUP:
      return { ...state, customerGroup: action.customerGroup, customerGroupConfig: action.newCustomerGroupConfig }
    case types.CLEAR_CUSTOMER_GROUP:
      return { ...state, customerGroup: '', customerGroupConfig: { logo: '', backgroundColor: '' } }
    default:
      return state
  }
}
