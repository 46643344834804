import { SessionResultsDto } from '../../objects/sessionResultsDto'
import {
  Session, SessionsDto, UsersDto, UsersSessionsDto, SessionEventsDto,
} from '../../objects/sessionsDto'
import fetchBackofficeAPI from '../../utils/NetworkUtils/fetch'

export const types = {
  REQUEST_SESSIONS: 'REQUEST_SESSIONS',
  RECEIVE_SESSIONS: 'RECEIVE_SESSIONS',
  RECEIVE_SESSIONS_ERROR: 'RECEIVE_SESSIONS_ERROR',
  SELECT_SESSION: 'SELECT_SESSION',

  REQUEST_USERS: 'REQUEST_USERS',
  RECEIVE_USERS: 'RECEIVE_USERS',
  RECEIVE_USERS_ERROR: 'RECEIVE_USERS_ERROR',

  REQUEST_USERS_SESSION: 'REQUEST_USERS_SESSION',
  RECEIVE_USERS_SESSION: 'RECEIVE_USERS_SESSION',
  RECEIVE_USERS_SESSION_ERROR: 'RECEIVE_USERS_SESSION_ERROR',

  REQUEST_SESSION_RESULT: 'REQUEST_SESSION_RESULT',
  RECEIVE_SESSION_RESULT: 'RECEIVE_SESSION_RESULT',
  RECEIVE_SESSION_RESULT_ERROR: 'RECEIVE_SESSION_RESULT_ERROR',

  REQUEST_SESSION_EVENTS: 'REQUEST_SESSION_EVENTS',
  RECEIVE_SESSION_EVENTS: 'RECEIVE_SESSION_EVENTS',
  RECEIVE_SESSION_EVENTS_ERROR: 'RECEIVE_SESSION_EVENTS_ERROR',

  REQUEST_SESSION_LOGS: 'REQUEST_SESSION_LOGS',
  RECEIVE_SESSION_LOGS: 'RECEIVE_SESSION_LOGS',
  RECEIVE_SESSION_LOGS_ERROR: 'RECEIVE_SESSION_LOGS_ERROR',

}

function requestSessions() {
  return {
    type: types.REQUEST_SESSIONS,
  }
}

function requestSessionEvents() {
  return {
    type: types.REQUEST_SESSION_EVENTS,
  }
}
function requestUsers() {
  return {
    type: types.REQUEST_USERS,
  }
}
function receiveSessions(sessions: SessionsDto) {
  return {
    type: types.RECEIVE_SESSIONS,
    sessions: sessions.sessionIds,
    totalSessions: sessions.totalSessions,
  }
}

function recieveSessionsEvents(events: SessionEventsDto) {
  return {
    type: types.RECEIVE_SESSION_EVENTS,
    events,
  }
}
function recieveSessionEventsError() {
  return {
    type: types.RECEIVE_SESSION_EVENTS_ERROR,
  }
}

function requestSessionLogs() {
  return {
    type: types.REQUEST_SESSION_LOGS,
  }
}

function recieveSessionsLogs(logs: string) {
  return {
    type: types.RECEIVE_SESSION_LOGS,
    logs,
  }
}
function recieveSessionLogsError() {
  return {
    type: types.RECEIVE_SESSION_LOGS_ERROR,
  }
}

function receiveUsers(users: UsersDto) {
  return {
    type: types.RECEIVE_USERS,
    users,
  }
}

function receiveSessionsError() {
  return {
    type: types.RECEIVE_SESSIONS_ERROR,
  }
}
function receiveUsersError() {
  return {
    type: types.RECEIVE_USERS_ERROR,
  }
}

export function fetchSessions(page: number, size: number, keyword: string, status: string) {
  let endpoint = `/sessions/sessionIds?page=${encodeURIComponent(page)}&size=${encodeURIComponent(size)}`
  if (keyword.length > 0) {
    endpoint += `&search=${encodeURIComponent(keyword)}`
  }
  if (status.length > 0) {
    endpoint += `&status=${encodeURIComponent(status)}`
  }
  return (dispatch) => {
    dispatch(requestSessions())
    return fetchBackofficeAPI(endpoint, 'GET')
      .then((response: SessionsDto) => {
        dispatch(receiveSessions(response))
      })
      .catch(() => {
        dispatch(receiveSessionsError())
      })
  }
}
export function fetchUsers(page: number, size: number, search: string, lastPage: number, start?: number, end?: number) {
  const params = new URLSearchParams({
    page: page.toString(),
    size: size.toString(),
    lastPage: lastPage.toString(),
  })

  if (search.length > 0) {
    params.set('search', search)
  }

  if (start && start > 0) {
    params.set('start', start.toString())
  }

  if (end && end > 0) {
    params.set('end', end.toString())
  }
  const endpoint = `/sessions/users?${params.toString()}`

  return (dispatch) => {
    dispatch(requestUsers())
    return fetchBackofficeAPI(endpoint, 'GET')
      .then((response: UsersDto) => {
        dispatch(receiveUsers(response))
      })
      .catch(() => {
        dispatch(receiveUsersError())
      })
  }
}

export function selectSession(sessionId: string) {
  return {
    type: types.SELECT_SESSION,
    sessionId,
  }
}

function requestSessionResult() {
  return {
    type: types.REQUEST_SESSION_RESULT,
  }
}
function requestUsersSession() {
  return {
    type: types.REQUEST_USERS_SESSION,
  }
}

function receiveSessionResult(sessionResult: SessionResultsDto) {
  return {
    type: types.RECEIVE_SESSION_RESULT,
    sessionResult,
  }
}
function receiveUsersSession(sessionResult: UsersSessionsDto) {
  return {
    type: types.RECEIVE_USERS_SESSION,
    usersSession: sessionResult.sessions,
  }
}

function receiveSessionResultError() {
  return {
    type: types.RECEIVE_SESSION_RESULT_ERROR,
  }
}
function receiveUsersSessionError() {
  return {
    type: types.RECEIVE_USERS_SESSION_ERROR,
  }
}

export function fetchSessionResult(sessionId: string) {
  const endpoint = `/sessions/sessionResults?session_ids=${encodeURIComponent(sessionId)}`
  return (dispatch) => {
    dispatch(requestSessionResult())
    return fetchBackofficeAPI(endpoint, 'GET')
      .then((response: SessionResultsDto) => {
        dispatch(receiveSessionResult(response))
      })
      .catch(() => {
        dispatch(receiveSessionResultError())
      })
  }
}
export function fetchUsersSession(sessionId: string) {
  const endpoint = `/sessions/user?sessionId=${sessionId}`
  return (dispatch) => {
    dispatch(requestUsersSession())
    return fetchBackofficeAPI(endpoint, 'GET')
      .then((response: UsersSessionsDto) => {
        dispatch(receiveUsersSession(response))
      })
      .catch(() => {
        dispatch(receiveUsersSessionError())
      })
  }
}
export function fetchSessionEvents(sessionId: string) {
  const endpoint = `/sessions/${sessionId}/events`
  return (dispatch) => {
    dispatch(requestSessionEvents())
    return fetchBackofficeAPI(endpoint, 'GET')
      .then((response: SessionEventsDto) => {
        dispatch(recieveSessionsEvents(response))
      })
      .catch(() => {
        dispatch(recieveSessionEventsError())
      })
  }
}
export function fetchSessionLogs(sessionId: string) {
  const endpoint = `/sessions/${sessionId}/logs`
  return (dispatch) => {
    dispatch(requestSessionLogs())
    return fetchBackofficeAPI(endpoint, 'GET')
      .then((response: string) => {
        dispatch(recieveSessionsLogs(response))
      })
      .catch(() => {
        dispatch(recieveSessionLogsError())
      })
  }
}
