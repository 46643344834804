export const types = {
  OPEN_APP_DRAWER: 'OPEN_APP_DRAWER',
  CLOSE_APP_DRAWER: 'CLOSE_APP_DRAWER',
}

export function openAppDrawer() {
  return {
    type: types.OPEN_APP_DRAWER,
  }
}

export function closeAppDrawer() {
  return {
    type: types.CLOSE_APP_DRAWER,
  }
}
