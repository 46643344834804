import {
  Box, IconButton, Tab, Tabs, Typography, useTheme,
} from '@mui/material'
import {
  ReactElement, useEffect, useMemo, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ReducerRootState from '../../objects/reducerRootState'
import { fetchSessionResult } from '../../services/ResultsService'
import LoadingView from '../LoadingView'
import PageLayout from '../PageLayout'
import UserInfoTab from './UserInfoTab'
import LivenessAttemptsTab from './LivenessAttemptsTab'
import IDScanAttemptsTab from './IDScanAttemptsTab'
import BrowserInfoTab from './BrowserInfoTab'
import alarm from '../../assets/alarm.png'
import DVSAttemptsTab from './DVSAttemptsTab'
import { clearCustomerGroup, setCustomerGroup } from '../../services/EnvironmentService/actions'
import { fetchSessionEvents, fetchSessionLogs } from '../../services/ResultsService/actions'
import SessionEventsTab from './SessionEventsTab'
import { selectUserScopes } from '../../services/AccountService/selectors'
import SessionRisksTab from './SessionRisksTab/SessionRisksTab'

function SessionDetailsPage() {
  const dispatch = useDispatch()
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()

  const urlParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const listingPage = parseInt(urlParams.get('listing_page') ?? '1', 10)
  const listingSearch = urlParams.get('listing_search') ?? ''
  const customerGroup = useSelector((state: ReducerRootState) => state.environment.customerGroup)
  const customerGroupConfig = useSelector((state: ReducerRootState) => state.environment.customerGroupConfig)
  const [tabValue, setTabValue] = useState(0)
  const [initialLoad, setInitialLoad] = useState(true)
  const userScopes = useSelector(selectUserScopes)
  const handleChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const { sessionId } = useParams<{ sessionId: string }>()
  useEffect(() => {
    dispatch(fetchSessionResult(sessionId))
    setInitialLoad(false)
    return () => {
      dispatch(clearCustomerGroup())
    }
  }, [])

  useEffect(() => {
    if (userScopes.indexOf('read:session_events') > 0) {
      dispatch(fetchSessionEvents(sessionId))
      setInitialLoad(false)
    }
  }, [])
  useEffect(() => {
    dispatch(fetchSessionLogs(sessionId))
    setInitialLoad(false)
  }, [])
  const sessionResult = useSelector((state: ReducerRootState) => state.results.sessionResult)
  const isLoadingSessionResult = useSelector((state: ReducerRootState) => state.results.isLoadingSessionResult)
  const sessionEvents = useSelector((state: ReducerRootState) => state.results.sessionEvents)
  const sessionLogs = useSelector((state: ReducerRootState) => state.results.sessionLogs)

  useEffect(() => {
    if (sessionResult?.originator) {
      dispatch(setCustomerGroup(sessionResult.originator))
    }
  }, [sessionResult])

  let tabContent: ReactElement | null = null
  if (tabValue === 0) {
    tabContent = (<BrowserInfoTab browserInfo={sessionResult?.browserInfo} sessionLogs={sessionLogs} />)
  }
  else if (tabValue === 1) {
    tabContent = (<LivenessAttemptsTab livenessAttempts={sessionResult?.attempts.livenessAttempts} />)
  }
  else if (tabValue === 2) {
    tabContent = (<IDScanAttemptsTab idScanAttempts={sessionResult?.attempts.idScanAttempts} />)
  }
  else if (tabValue === 3) {
    tabContent = (
      <UserInfoTab
        userInfo={sessionResult?.userInfo}
        modifiedOCRResults={sessionResult?.modifiedOCRResults}
        originalOCRResult={sessionResult?.ocrResults}
      />
    )
  }
  else if (tabValue === 4) {
    tabContent = (<DVSAttemptsTab dvsAttempts={sessionResult?.attempts.dvsAttempts} />)
  }
  else if (tabValue === 5 && (userScopes.indexOf('read:session_risks') > 0)) {
    tabContent = (
      <SessionRisksTab
        sessionIPRisks={sessionResult?.browserInfo?.ipAddressRisks}
        sessionIdScanAttempts={sessionResult?.userInfo?.idList[0].registrationPlace !== 'QLD'
          ? sessionResult?.attempts.idScanAttempts
          : undefined}
        sessionRiskEvents={sessionResult?.riskEvents}
      />
    )
  }
  else if (tabValue === 6 && (userScopes.indexOf('read:session_events') > 0)) {
    tabContent = (<SessionEventsTab sessionEvents={sessionEvents} />)
  }
  let content = (
    <Box
      position="relative"
      display="flex"
      flex={1}
      pt={4}
      flexDirection="column"
      textAlign="center"
      alignItems="center"
    >
      <IconButton
        aria-label="back"
        color="primary"
        onClick={() => {
          navigate(`/sessions?page=${listingPage}&search=${listingSearch}`)
        }}
        style={{
          position: 'absolute',
          left: 0,
          top: 24,
          color: customerGroup.length > 0 ? customerGroupConfig.backgroundColor : 'primary',
        }}
      >
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h6" style={{ color: '#ffffff' }}>
        Session ID :
        {' '}
        {sessionId}
      </Typography>
      <Typography variant="h6" style={{ color: '#ffffff' }}>
        {' '}
        {`Name : ${sessionResult?.userInfo?.name?.first} ${sessionResult?.userInfo?.name?.middle ?? ''} 
        ${sessionResult?.userInfo?.name?.last}`}
      </Typography>
      <Box mt={4} mb={4} display="flex" flex={1} style={{ width: '100%' }}>
        <Box width="100%" color={theme.palette.common.white}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            TabIndicatorProps={{ style: { backgroundColor: customerGroup.length > 0 ? customerGroupConfig.backgroundColor : 'primary' } }}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Session Info" />
            <Tab label="Liveness Attempts" />
            <Tab
              label="ID Scan Attempts"
            />
            <Tab label="Confirmed User Info" />
            <Tab label="DVS Attempts" />
            { userScopes.indexOf('read:session_risks') > 0 && (
              <Tab
                label="Risks"
                icon={(sessionResult?.attempts.idScanAttempts.front[0]?.faceOnDocumentStatus === 'CANNOT_CONFIRM_ID_IS_AUTHENTIC'
                && sessionResult?.userInfo?.idList[0].registrationPlace !== 'QLD')
                  ? (<img alt="test avatar" style={{ width: '24px', marginRight: '5px' }} src={alarm} />)
                  : null}
              />
            )}
            { userScopes.indexOf('read:session_events') > 0 && <Tab label="Session Events" />}
          </Tabs>
          {tabContent}
        </Box>

      </Box>
    </Box>
  )

  if (initialLoad || isLoadingSessionResult) {
    content = <LoadingView />
  }
  return (
    <PageLayout>
      {content}
    </PageLayout>
  )
}

export default SessionDetailsPage
