import { useAuth0 } from '@auth0/auth0-react'
import {
  Box,
  Button, Typography, useMediaQuery, useTheme,
} from '@mui/material'
import LockIcon from '@mui/icons-material/Lock'
import { useLocation } from 'react-router-dom'

function LoginButton() {
  const { loginWithRedirect } = useAuth0()
  const theme = useTheme()
  const location = useLocation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Box
      pr={isMobile ? 2 : 0}
      pt={isMobile ? 2 : 0}
      pb={isMobile ? 2 : 0}
      flex={1}
      display="flex"
      justifyContent="flex-end"
      style={{ backgroundColor: isMobile ? theme.palette.primary.light : '' }}
    >
      <Button
        style={{
          textDecoration: 'none', color: 'white', display: 'flex', justifyContent: 'center',
        }}
        onClick={() => {
          loginWithRedirect({
            appState: {
              returnTo: location.pathname,
            },
          })
        }}
        startIcon={<LockIcon color="secondary" />}
      >
        <Typography variant="button" color="secondary">
          Log In
        </Typography>
      </Button>
    </Box>
  )
}

export default LoginButton
