import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'

interface SessionSearchBoxProps {
  value: string
  onChange: (string) => void
  onSearchSubmit: () => void
}

const SessionsSearchBox = (props: SessionSearchBoxProps) => {
  const { value, onChange, onSearchSubmit } = props
  return (
    <Paper
      component="form"
      style={{
        padding: '24px', display: 'flex', alignItems: 'center', height: '30px', backgroundColor: 'rgb(78 85 96)', borderRadius: '25px',
      }}
    >
      <InputBase
        placeholder="Search Sessions"
        inputProps={{ 'aria-label': 'search sessions' }}
        style={{ flex: 1, color: '#fff' }}
        value={value}
        onChange={(event) => {
          onChange(event.target.value)
        }}
      />
      <IconButton type="submit" aria-label="search" style={{ padding: 10, color: '#fff' }} onClick={() => onSearchSubmit()}>
        <SearchIcon />
      </IconButton>
    </Paper>
  )
}

export default SessionsSearchBox
