import fetchBackofficeAPI from '../../utils/NetworkUtils/fetch'
import {
  chartResultsDto, userOSResultsDto, userIDtypeResultsDto, statisticUserResultsDto,
} from '../../objects/chartResultsDto'

export const types = {

  REQUEST_CHART_DATA: 'REQUEST_CHART_DATA',
  RECEIVE_CHART_DATA: 'RECEIVE_CHART_DATA',
  RECEIVE_CHART_DATA_ERROR: 'RECEIVE_CHART_DATA_ERROR',

  REQUEST_CHART_USER_OS_DATA: 'REQUEST_CHART_USER_OS_DATA',
  RECEIVE_CHART_USER_OS_DATA: 'RECEIVE_CHART_USER_OS_DATA',
  RECEIVE_CHART_USER_OS_DATA_ERROR: 'RECEIVE_CHART_USER_OS_DATA_ERROR',

  REQUEST_CHART_USER_ID_TYPE_DATA: 'REQUEST_CHART_USER_ID_TYPE_DATA',
  RECEIVE_CHART_USER_ID_TYPE_DATA: 'RECEIVE_CHART_USER_ID_TYPE_DATA',
  RECEIVE_CHART_USER_ID_TYPE_ERROR: 'RECEIVE_CHART_USER_ID_TYPE_ERROR',

  REQUEST_USER_STATISTICS_DATA: 'REQUEST_USER_STATISTICS_DATA',
  RECEIVE_USER_STATISTICS_DATA: 'RECEIVE_USER_STATISTICS_DATA',
  RECEIVE_USER_STATISTICS_ERROR: 'RECEIVE_USER_STATISTICS_ERROR',

}

function requestuserStatisticsData() {
  return {
    type: types.REQUEST_USER_STATISTICS_DATA,

  }
}

function receiveuserStatisticsData(userStatisticsCount: statisticUserResultsDto) {
  return {
    type: types.RECEIVE_USER_STATISTICS_DATA,
    userStatisticsCount,
  }
}

function receiveuserStatisticsError() {
  return {
    type: types.RECEIVE_USER_STATISTICS_ERROR,
  }
}

function requestChartData() {
  return {
    type: types.REQUEST_CHART_DATA,
  }
}

function receiveChartData(response: chartResultsDto) {
  return {
    type: types.RECEIVE_CHART_DATA,
    specificCount: response.specificCount,
  }
}

function receiveChartError() {
  return {
    type: types.RECEIVE_CHART_DATA_ERROR,
  }
}

export function fetchChartData(fromDate: string, toDate: string) {
  const endpoint = `/sessions/statistics/specific?from=${encodeURIComponent(fromDate)}&to=${encodeURIComponent(toDate)}`
  return (dispatch) => {
    dispatch(requestChartData())
    return fetchBackofficeAPI(endpoint, 'GET')
      .then((response: chartResultsDto) => dispatch(receiveChartData(response)))
      .catch(() => dispatch(receiveChartError()))
  }
}
export function fetchUserStatisticsData(fromDate: string, toDate: string) {
  const endpoint = `/statistics/users?from=${encodeURIComponent(fromDate)}&to=${encodeURIComponent(toDate)}`
  return (dispatch) => {
    dispatch(requestuserStatisticsData())
    return fetchBackofficeAPI(endpoint, 'GET')
      .then((response: statisticUserResultsDto) => dispatch(receiveuserStatisticsData(response)))
      .catch(() => dispatch(receiveuserStatisticsError()))
  }
}

function requestChartUserOSData() {
  return {
    type: types.REQUEST_CHART_USER_OS_DATA,
  }
}

function receiveChartUserOSData(response: userOSResultsDto) {
  return {
    type: types.RECEIVE_CHART_USER_OS_DATA,
    osCount: response.osCount,
  }
}

function receiveChartUserOSError() {
  return {
    type: types.RECEIVE_CHART_USER_OS_DATA_ERROR,
  }
}

export function fetchChartUserOSData(fromDate: string, toDate: string) {
  const endpoint = `/sessions/statistics/os?from=${encodeURIComponent(fromDate)}&to=${encodeURIComponent(toDate)}`

  return (dispatch) => {
    dispatch(requestChartUserOSData())
    return fetchBackofficeAPI(endpoint, 'GET')
      .then((response: userOSResultsDto) => dispatch(receiveChartUserOSData(response)))
      .catch(() => dispatch(receiveChartUserOSError()))
  }
}

function requestChartUserIDTypeData() {
  return {
    type: types.REQUEST_CHART_USER_ID_TYPE_DATA,
  }
}

function receiveChartUserIDTypeData(response: userIDtypeResultsDto) {
  return {
    type: types.RECEIVE_CHART_USER_ID_TYPE_DATA,
    idTypeCount: response.idTypeCount,
  }
}

function receiveChartUserIDTypeError() {
  return {
    type: types.RECEIVE_CHART_USER_ID_TYPE_ERROR,
  }
}

export function fetchChartUserIDTypeData(fromDate: string, toDate: string) {
  const endpoint = `/sessions/statistics/idType?from=${encodeURIComponent(fromDate)}&to=${encodeURIComponent(toDate)}`

  return (dispatch) => {
    dispatch(requestChartUserIDTypeData())
    return fetchBackofficeAPI(endpoint, 'GET')
      .then((response: userIDtypeResultsDto) => dispatch(receiveChartUserIDTypeData(response)))
      .catch(() => dispatch(receiveChartUserIDTypeError()))
  }
}
