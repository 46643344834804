import { createTheme } from '@mui/material/styles'
import { StoreRegistry } from '../../stores'
import * as tenantThemes from '../../themes'
import baseStyles from '../../index.css?raw'

export const types = {
  SET_THEME: 'SET_THEME',
}

function setTheme(theme) {
  return {
    type: types.SET_THEME,
    theme,
  }
}

export function setupTheme(tenant: string) {
  const { store } = StoreRegistry
  let tenantTheme
  if (tenant === 'nowfinance') {
    tenantTheme = tenantThemes.NowFinanceTheme.default
  }
  else if (tenant === 'bryk') {
    tenantTheme = tenantThemes.BrykTheme.default
  }
  else {
    tenantTheme = {}
  }

  const tenantMuiTheme = createTheme({
    components: {
      MuiCssBaseline: {
        styleOverrides: baseStyles,
      },
    },
    ...tenantTheme,
  })
  store.dispatch(setTheme(tenantMuiTheme))
}
