import React from 'react'
import { useNavigate, useMatch, useLocation } from 'react-router-dom'
import {
  ListItem, Typography, Collapse, IconButton, List, Box,
} from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import { closeAppDrawer } from '../../services/NavigationService'
import { NavigationItem } from '../../objects/navigationItem'

function AppDrawerItem(props: NavigationItem) {
  const {
    redirectPath, highlightedColor,
    itemTitle, subTab,
  } = props

  const [collapseOpen, setCollapseOpen] = React.useState(false)

  const theme = useTheme()
  const navigate = useNavigate()
  const routeMatch = useMatch(redirectPath)
  const location = useLocation()
  const dispatch = useDispatch()

  let expandIcon = <ExpandMore fontSize="large" style={{ color: theme.palette.common.white }} />
  if (collapseOpen) {
    expandIcon = <ExpandLess fontSize="large" style={{ color: theme.palette.common.white }} />
  }

  return (
    <>
      <ListItem
        button
        style={{
          paddingTop: '16px',
          paddingBottom: '16px',
          paddingLeft: '24px',
          paddingRight: '24px',
          backgroundColor: routeMatch ? highlightedColor : '#FFFFFF00',
          display: 'flex',
        }}
      >
        <Typography
          variant="h4"
          style={{ color: theme.palette.common.white, flex: 1 }}
          onClick={() => {
            dispatch(closeAppDrawer())
            navigate(`${redirectPath}${location.search}`)
          }}
        >
          {itemTitle}
        </Typography>
        {subTab.length > 0
          ? (
              <IconButton onClick={() => setCollapseOpen(!collapseOpen)}>
                {expandIcon}
              </IconButton>
            )
          : null}
      </ListItem>
      <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {subTab.map(subItem => (
            <ListItem
              button
              key={subItem.subtitle}
              onClick={() => {
                dispatch(closeAppDrawer())
                navigate(`${subItem.redirectPath}${location.search}`)
              }}
            >
              <Box pl={3} pr={3}>
                <Typography
                  variant="h6"
                  noWrap
                  style={{ color: theme.palette.common.white }}
                >
                  <span>
                    {subItem.subtitle}
                  </span>
                </Typography>
              </Box>

            </ListItem>
          ))}

        </List>
      </Collapse>
    </>
  )
}

export default AppDrawerItem
