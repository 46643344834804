import {
  createStore, applyMiddleware, combineReducers, compose,
} from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { thunk } from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { configReducer } from '../services/ConfigService'
import { environmentReducer } from '../services/EnvironmentService'
import { themeReducer } from '../services/ThemeService'
import { navigationReducer } from '../services/NavigationService'
import { resultsReducer } from '../services/ResultsService'
import { accountReducer } from '../services/AccountService'
import { userManagementReducer } from '../services/UserManagementService'
import { chartDataReducer } from '../services/chartService'

let finalCreateStore
if (import.meta.env.NODE_ENV === 'production') {
  finalCreateStore = compose(applyMiddleware(thunk))(createStore)
}
else {
  finalCreateStore = compose(applyMiddleware(thunk, createLogger()))(createStore)
}

const accountPersistConfig = {
  key: 'account',
  storage,
  whitelist: ['accessToken', 'loggedIn', 'refreshToken'],
}

const reducer = combineReducers({
  account: persistReducer(accountPersistConfig, accountReducer),
  config: configReducer,
  environment: environmentReducer,
  navigation: navigationReducer,
  results: resultsReducer,
  theme: themeReducer,
  userManagement: userManagementReducer,
  chart: chartDataReducer,
})

const store = finalCreateStore(reducer)
const persistor = persistStore(store, null)

export { store, persistor }
