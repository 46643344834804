import ChartsState from '../../objects/chartsState'
import { types } from './actions'

const initialState: ChartsState = {
  isLoadingCharts: false,
  specificCount: null,
  osCount: null,
  idTypeCount: null,
  //   totalSessions: 0,
  isChartDataError: false,
  userStatisticsCount: null,
}

export default function chartDataReducer(state: ChartsState = initialState, action): ChartsState {
  switch (action.type) {
    case types.REQUEST_USER_STATISTICS_DATA:
      return {
        ...state, isLoadingCharts: true, userStatisticsCount: null, isChartDataError: false,
      }
    case types.RECEIVE_USER_STATISTICS_DATA:
      return {
        ...state, isLoadingCharts: false, userStatisticsCount: action.userStatisticsCount, isChartDataError: false,
      }
    case types.REQUEST_CHART_DATA:
      return {
        ...state, isLoadingCharts: true, specificCount: null, isChartDataError: false,
      }
    case types.RECEIVE_CHART_DATA:
      return {
        ...state, isLoadingCharts: false, specificCount: action.specificCount, isChartDataError: false,
      }
    case types.RECEIVE_CHART_DATA_ERROR:
      return {
        ...state, isLoadingCharts: false, specificCount: null, isChartDataError: true,
      }
    case types.REQUEST_CHART_USER_OS_DATA:
      return {
        ...state, isLoadingCharts: true, osCount: null, isChartDataError: false,
      }
    case types.RECEIVE_CHART_USER_OS_DATA:
      return {
        ...state, isLoadingCharts: false, osCount: action.osCount, isChartDataError: false,
      }
    case types.RECEIVE_CHART_USER_OS_DATA_ERROR:
      return {
        ...state, isLoadingCharts: false, osCount: null, isChartDataError: true,
      }
    case types.REQUEST_CHART_USER_ID_TYPE_DATA:
      return {
        ...state, isLoadingCharts: true, idTypeCount: null, isChartDataError: false,
      }
    case types.RECEIVE_CHART_USER_ID_TYPE_DATA:
      return {
        ...state, isLoadingCharts: false, idTypeCount: action.idTypeCount, isChartDataError: false,
      }
    case types.RECEIVE_CHART_USER_ID_TYPE_ERROR:
      return {
        ...state, isLoadingCharts: false, idTypeCount: null, isChartDataError: true,
      }
    default:
      return state
  }
}
