import React, { useEffect } from 'react'
import { CssBaseline, ThemeProvider, unstable_useEnhancedEffect } from '@mui/material'
import { useSelector } from 'react-redux'
import {
  Route, Routes, HashRouter, Outlet, useLocation, createHashRouter, useNavigate, NavigateFunction,
} from 'react-router-dom'
import ReducerRootState from '../../objects/reducerRootState'
import NavigationBar from '../NavigationBar'
import PageHeader from '../PageHeader'
import SessionDetailsPage from '../SessionDetailsPage'
import SessionListingPage from '../SessionListingPage'
import LandingPage from '../LandingPage'
import DashboardPage from '../DashboardPage'
import UsersManagementPage from '../UsersManagementPage'
import UserManagementPage from '../UserManagementPage'
// import SessionUsersListingPage from '../SessionUsersListingPage';
import { withAuthenticationRequired } from '@auth0/auth0-react'

export const navigatePtr: { navigate: NavigateFunction } = {
  navigate() {
    console.warn('Tried to navigate when router not initialized!')
  },
}

function StoreRouterReference() {
  navigatePtr.navigate = useNavigate()
  return null
}

function ProtectedRouteGuard(): React.ReactElement {
  const location = useLocation()

  const AuthedRoute = withAuthenticationRequired(Outlet, { returnTo: location.pathname })

  return <AuthedRoute />
}

function AppRouter() {
  const theme = useSelector((state: ReducerRootState) => state.theme.theme)
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div style={{ minHeight: 'calc(100vh - 64px)' }}>
        <HashRouter>
          <StoreRouterReference />
          <PageHeader />
          <NavigationBar />
          <Routes>
            <Route path="/" element={<DashboardPage />} />
            <Route path="/" element={<ProtectedRouteGuard />}>
              <Route path="/start" element={<LandingPage />} />
              <Route path="/sessions/:sessionId" element={<SessionDetailsPage />} />
              <Route path="/sessions" element={<SessionListingPage />} />
              {/* This page is non-functional on the backend due to the session server being basically unused, so it's being killed off for now. */}
              {/* This is being commented out so we can avoid including it into the final build. */}
              {/* <Route path="/userslist" element={<SessionUsersListingPage />} /> */}
              <Route path="/users/:userId" element={<UserManagementPage />} />
              <Route path="/users" element={<UsersManagementPage />} />
            </Route>

            {/* <Route path="/dashboard" component={DashboardPage} /> */}
          </Routes>
        </HashRouter>
      </div>
    </ThemeProvider>

  )
}

export default AppRouter
