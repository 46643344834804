const navigationItems = [
  {
    defaultIcon: <div />,
    hoverIcon: <div />,
    selectedIcon: <div />,
    redirectPath: '/',
    itemTitle: 'Home',
    highlightedColor: '',
    subTab: [],
    requiredScopes: [],
  },
  {
    defaultIcon: <div />,
    hoverIcon: <div />,
    selectedIcon: <div />,
    redirectPath: '/sessions',
    itemTitle: 'Sessions',
    highlightedColor: '',
    subTab: [],
    requiredScopes: ['read:session_results'],
  },
  // The users page does not currently do anything on the backend. Just kill it for now.
  /* {
    defaultIcon: <div />,
    hoverIcon: <div />,
    selectedIcon: <div />,
    redirectPath: '/userslist',
    itemTitle: 'Users',
    highlightedColor: '',
    subTab: [],
    requiredScopes: ['read:session_results'],
  }, */
  {
    defaultIcon: <div />,
    hoverIcon: <div />,
    selectedIcon: <div />,
    redirectPath: '/users',
    itemTitle: 'User Management',
    highlightedColor: '',
    subTab: [],
    requiredScopes: ['manage:users'],
  },
]

export default navigationItems
