import { useTheme } from '@mui/material/styles'
import GridLoader from 'react-spinners/GridLoader'

function LoadingView() {
  const theme = useTheme()
  return (
    <div style={{
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}
    >
      <GridLoader color={theme.palette.primary.main} />
    </div>
  )
}

export default LoadingView
