import React from 'react'
import { createRoot } from 'react-dom/client'
// This instead gets imported in ThemeService/actions.ts
// import './index.css';
import App from './components/App'

createRoot(
  document.getElementById('root')!,
).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
