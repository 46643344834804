import {
  Box, Card, CardContent, CardMedia, Typography,
} from '@mui/material'
import '../../../styles/sessionDetails.css'
import arrow from '../../../assets/arrows.png'

function DisplayField(props: { fieldName: string, value: any }): JSX.Element {
  const { fieldName, value } = props
  return (
    <Typography style={{ textAlign: 'center' }}>
      <Typography variant="inherit" style={{ fontWeight: 'bold' }}>{`${fieldName}:`}</Typography>
      {` ${value} `}
    </Typography>
  )
}

function SessionEvent(props: { index: number, event: any }): JSX.Element {
  const { index, event } = props

  const createdAt = new Date(event.createdAt)
    .toLocaleTimeString('en-AU', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    })

  const properties = Object.entries(event.data)
    .map(([key, value]) => <DisplayField fieldName={key} value={value} />)

  return (
    <Box mb={2} sx={{ width: '300px', p: 1 }}>
      <Card className="container" title="Browser History">
        <CardContent className="card-content" title="Browser History" style={{ height: '200px', overflowY: 'auto' }}>
          <Box mb={2}>
            <CardMedia
              component="img"
              style={{
                display: 'inline',
                width: '2rem',
              }}
              image={arrow}
            />
            <Typography variant="h6">
              <b>{`Step ${index + 1}`}</b>
            </Typography>
            <Typography variant="body2">
              {event.type}
            </Typography>
            <Typography variant="body2">
              {createdAt}
            </Typography>
          </Box>
          {properties}
        </CardContent>
      </Card>
    </Box>
  )
}

function InstanceEvents(props: { instanceId: string, events: any[] }): JSX.Element {
  const { instanceId, events } = props

  return (
    <Box>
      <Typography
        className="eventStep"
        variant="h4"
      >
        {`Instance : ${instanceId}`}

      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        { events.map((event, index) => (
          <SessionEvent key={event.sessionId} index={index} event={event} />
        ))}
      </Box>
    </Box>
  )
}

export default function SessionEventsTab(props: any) {
  const { sessionEvents } = props || []
  const instanceEvents = sessionEvents.events.reduce((instances: any, event: any) => {
    if (instances[event.instanceId]) {
      // eslint-disable-next-line no-param-reassign
      instances[event.instanceId].push(event)
    }
    else {
      // eslint-disable-next-line no-param-reassign
      instances[event.instanceId] = [event]
    }

    return instances
  }, {})
  return (
    <Box sx={{ display: 'inline' }}>
      {(Object.keys(instanceEvents).length === 0)
      && (
        <Box mt={6}>
          <Typography variant="body1">
            There is no session Events
            for this session
          </Typography>
        </Box>
      )}
      { Object.entries(instanceEvents).map(([instanceId, events]: any) => (
        <InstanceEvents instanceId={instanceId} events={events} />
      ))}
    </Box>
  )
}
