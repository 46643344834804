import {
  Box, Typography, Card, CardHeader, CardContent, TextField, Button, Avatar, IconButton,
} from '@mui/material'
import {
  Bar, Line,
} from 'react-chartjs-2'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { useAuth0 } from '@auth0/auth0-react'
import PageLayout from '../PageLayout'

import ReducerRootState from '../../objects/reducerRootState'
import { fetchChartUserOSData, fetchChartUserIDTypeData, fetchUserStatisticsData } from '../../services/chartService'
import PieChart from './PieChart'
import theme from '../../themes/bryk'
import barGraph from '../../assets/graph.png'
import circlegraph from '../../assets/bar-graph.png'
import imgGraph from '../../assets/graph2.png'
import lineGraph from '../../assets/pie-chart.png'
import alarm from '../../assets/access.jpg'
import '../../styles/sessionsDasboard.css'
import LoadingView from '../LoadingView/LoadingView'
import SessionsByUserOSChart from './SessionsByUserOSChart'
import SessionsByUserIDTypeChart from './SessionsByUserIDTypeChart'
import SessionHistoryCard from './SessionHistoryCard'
import { selectUserScopes } from '../../services/AccountService/selectors'

function DashboardPage() {
  const dispatch = useDispatch()
  const isLoadingCharts = useSelector((state: ReducerRootState) => state.chart?.isLoadingCharts)
  const isChartDataError = useSelector((state: ReducerRootState) => state.chart?.isChartDataError)
  const [fromDate, setFromDate] = useState('2021-12-17T10:30')
  const [toDate, setToDate] = useState('2021-12-18T10:30')
  const [initialLoad, setInitialLoad] = useState(true)
  const [formattedFromDate, setFormattedFromDate] = useState(moment(fromDate).format('YYYY-MM-DD HH:mm:ss'))
  const [formattedToDate, setFormattedToDate] = useState(moment(toDate).format('YYYY-MM-DD HH:mm:ss'))
  const { loggedIn } = useSelector((state: ReducerRootState) => state.account)
  const { userStatisticsCount } = useSelector((state: ReducerRootState) => state?.chart || {})
  const { user } = useAuth0()
  const userScopes = useSelector(selectUserScopes)
  const startOfTodayDate = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
  const endtOfTodayDate = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
  const startOfWeekDate = moment().startOf('week').format('YYYY-MM-DD HH:mm:ss')
  const endOFWeekDate = moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
  const startOfMonthDate = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss')
  const endOfMonthDate = moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')
  const startOfQuarterDate = moment().startOf('quarter').format('YYYY-MM-DD HH:mm:ss')
  const endOfQuarterDate = moment().endOf('quarter').format('YYYY-MM-DD HH:mm:ss')

  useEffect(() => {
    // dispatch(fetchChartData(formattedFromDate, formattedToDate));
    setInitialLoad(false)
  }, [])
  useEffect(() => {
    dispatch(fetchUserStatisticsData(formattedFromDate, formattedToDate))
    setInitialLoad(false)
  }, [])

  useEffect(() => {
    // dispatch(fetchChartUserOSData(fromDate, toDate));
  }, [])

  useEffect(() => {
    // dispatch(fetchChartUserIDTypeData(fromDate, toDate));
  }, [])

  const sessionResultsInfo = {
    labels: ['Successful Users', 'Partial Users', 'Failed Users', 'Successful Rate',
      'Partilal Rate', 'Failed Rate',
      'Not Started User', 'Not Started Rate'],

    datasets: [
      {

        backgroundColor: [
          theme.palette.pieChart.green,
          theme.palette.pieChart.yellow,
          theme.palette.pieChart.blue,
          theme.palette.pieChart.green,
          theme.palette.pieChart.yellow,
          theme.palette.pieChart.blue,
          theme.palette.pieChart.purple,
          theme.palette.pieChart.gray,
        ],

        data: [userStatisticsCount?.successfulUser, userStatisticsCount?.partialSuccessUser,
          userStatisticsCount?.failedUser, userStatisticsCount?.successfulRate?.toFixed(2),
          userStatisticsCount?.partialSuccessRate?.toFixed(2),
          userStatisticsCount?.failedRate?.toFixed(2), userStatisticsCount?.notStartedUser, userStatisticsCount?.notStartedRate?.toFixed(2)],
      },
    ],
  }
  // this is only  mock data to display the graph and the API is not ready yet
  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
    datasets: [
      {
        label: 'Completed sessions',
        data: [33, 53, 85, 41, 44, 65, 40, 90, 20],
        fill: true,
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)',
      },
      {
        label: 'Failed sessions',
        data: [33, 25, 35, 51, 54, 76, 90, 20, 30],
        fill: false,
        borderColor: '#742774',
      },
    ],
  }

  let content = (

    <Box display="flex" height="100%" flex={1} mt={4} flexDirection="column" alignItems="center" justifyContent="start">
      <Typography variant="h4" className="dasboard-header">
        Bryk.ID Console
      </Typography>
      {(loggedIn && userStatisticsCount) ? (
        <>
          <Box display="flex" flexDirection="row" mb={3} alignItems="center">
            <TextField
              className="text"
              id="datetime-local"
              label="From Date"
              variant="outlined"
              type="datetime-local"
              value={fromDate}
              onChange={(el) => {
                setFromDate(el.currentTarget.value)
                setFormattedFromDate(moment(el.currentTarget.value).format('YYYY-MM-DD HH:mm:ss'))
              }}
              InputProps={{
                classes: {
                  root: 'text',
                  focused: 'focused',
                  notchedOutline: 'notchedOutline',
                },
              }}
              InputLabelProps={{
                shrink: true,
                className: 'text',
              }}
            />
            <TextField
              variant="outlined"
              className="text"
              label="To Date"
              type="datetime-local"
              value={toDate}
              onChange={(el) => {
                setToDate(el.currentTarget.value)
                setFormattedToDate(moment(el.currentTarget.value).format('YYYY-MM-DD HH:mm:ss'))
              }}
              InputProps={{
                style: {
                  fill: 'white',
                },
                classes: {
                  root: 'text',
                  focused: 'focused',
                  notchedOutline: 'notchedOutline',
                  adornedEnd: 'adornedEnd',
                  inputAdornedEnd: 'adornedEnd',

                },

              }}
              InputLabelProps={{
                className: 'text',

              }}
            />
            <Button
              variant="contained"
              color="primary"
              size="large"
              disableElevation
              style={{
                color: 'white',
                marginLeft: '1rem',
              }}
              onClick={() => {
                // dispatch(fetchChartData(formattedFromDate, formattedToDate));
                dispatch(fetchChartUserIDTypeData(formattedFromDate, formattedToDate))
                dispatch(fetchChartUserOSData(formattedFromDate, formattedToDate))
              }}
            >
              Go
            </Button>
          </Box>

          <Box mt={2} mb={3} display="flex" flexWrap="noWrap">

            <Button
              variant="contained"
              size="large"
              startIcon={<Avatar src={imgGraph} />}
              disableElevation
              className="dasboard-toolbar"
              onClick={() => {
                // dispatch(fetchChartData(startOfTodayDate, endtOfTodayDate));
                dispatch(fetchChartUserIDTypeData(startOfTodayDate, endtOfTodayDate))
                dispatch(fetchChartUserOSData(startOfTodayDate, endtOfTodayDate))
                setFromDate(moment(startOfTodayDate).format('YYYY-MM-DD[T]HH:mm:ss'))
                setToDate(moment(startOfTodayDate).format('YYYY-MM-DD[T]HH:mm:ss'))
              }}
            >
              Users Today
            </Button>
            <Button
              variant="contained"
              size="large"
              startIcon={<Avatar src={barGraph} />}
              disableElevation
              className="dasboard-toolbar"
              onClick={() => {
                // dispatch(fetchChartData(startOfWeekDate, endOFWeekDate));
                dispatch(fetchChartUserIDTypeData(startOfWeekDate, endOFWeekDate))
                dispatch(fetchChartUserOSData(startOfWeekDate, endOFWeekDate))
                setFromDate(moment(startOfWeekDate).format('YYYY-MM-DD[T]HH:mm:ss'))
                setToDate(moment(endOFWeekDate).format('YYYY-MM-DD[T]HH:mm:ss'))
              }}
            >
              Users this week
            </Button>
            <Button
              variant="contained"
              size="large"
              startIcon={<Avatar src={circlegraph} />}
              disableElevation
              className="dasboard-toolbar"
              onClick={() => {
                // dispatch(fetchChartData(startOfMonthDate, endOfMonthDate));
                dispatch(fetchChartUserIDTypeData(startOfMonthDate, endOfMonthDate))
                dispatch(fetchChartUserOSData(startOfMonthDate, endOfMonthDate))
                setFromDate(moment(startOfMonthDate).format('YYYY-MM-DD[T]HH:mm:ss'))
                setToDate(moment(endOfMonthDate).format('YYYY-MM-DD[T]HH:mm:ss'))
              }}
            >
              Sessions this month
            </Button>
            <Button
              variant="contained"
              startIcon={<Avatar src={lineGraph} />}
              size="large"
              disableElevation
              className="dasboard-toolbar"
              onClick={() => {
                // dispatch(fetchChartData(startOfQuarterDate, endOfQuarterDate));
                dispatch(fetchChartUserIDTypeData(startOfQuarterDate, endOfQuarterDate))
                dispatch(fetchChartUserOSData(startOfQuarterDate, endOfQuarterDate))
                setFromDate(moment(startOfQuarterDate).format('YYYY-MM-DD[T]HH:mm:ss'))
                setToDate(moment(endOfQuarterDate).format('YYYY-MM-DD[T]HH:mm:ss'))
              }}
            >
              Users this Quarter
            </Button>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            mt={2}
          >
            <Card className="chart-card" variant="outlined">
              <CardHeader title="Users History" className="card-header-title" />
              <CardContent className="chart-card-content">
                <SessionHistoryCard userStatisticsCount={userStatisticsCount} />
              </CardContent>
            </Card>
            <Card className="chart-card" variant="outlined">
              <CardHeader
                title="Users status tally"
                className="card-header-title"
              />
              <CardContent>
                <Bar
                  height={300}
                  width={300}
                  data={sessionResultsInfo}
                  options={{
                    scales: {
                      x: {
                        ticks: {
                          color: '#fff',
                        },
                      },
                      y: {
                        ticks: {
                          color: '#fff',
                          precision: 0,
                        },
                      },
                    },
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                />
              </CardContent>
            </Card>
            <Card className="chart-card" variant="outlined">
              <CardHeader title="Users state" className="card-header-title" />
              <CardContent>
                <PieChart pieChartData={userStatisticsCount} />
              </CardContent>
            </Card>
          </Box>
          <Box
            flexDirection="row"
            mt={2}
            mb={3}
            style={{ width: '90%' }}
          >
            <Card className="chart-card" variant="outlined">
              <CardHeader title="Users per months" className="card-header-title" />
              <CardContent>
                <Line
                  height={250}
                  width={300}
                  data={data}
                  options={{
                    scales: {
                      x: {
                        ticks: {
                          color: '#fff',
                        },
                      },
                      y: {
                        ticks: {
                          color: '#fff',
                          precision: 0,
                        },
                      },
                    },
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        position: 'bottom',
                        align: 'start',
                        display: true,
                        labels: {
                          color: 'white',

                        },
                      },
                    },
                  }}
                />

              </CardContent>
            </Card>
          </Box>

        </>
      )
        : (
            <Box>

              {
                (loggedIn && !userScopes.includes('read:session_results'))
                  ? (
                      <>
                        <Box style={{
                          border: '1px solid white', background: '#ffffff', borderRadius: '15px', padding: '2rem',
                        }}
                        >
                          <Box style={{ display: 'flex', justifyContent: 'center' }}>
                            <img
                              alt="idImage"
                              src={alarm}
                              height="300px"
                            />
                          </Box>
                          <Typography
                            variant="h6"
                            className="logout-error"
                          >
                            {user?.name && (
                              <>
                                <Typography variant="h6" style={{ color: 'black' }}>

                                  <b>{user?.email}</b>
                                  {' '}

                                  is not yet authorised to access the Bryk.ID Console
                                </Typography>
                                <Typography variant="h6" style={{ color: 'black' }}>

                                  please contact the administrator
                                </Typography>
                              </>

                            )}
                          </Typography>
                        </Box>
                      </>
                    )
                  : <></>
              }
            </Box>
          )}
    </Box>

  )
  if (initialLoad) {
    content = <LoadingView />
  }
  return (
    <PageLayout>
      {content}
    </PageLayout>
  )
}

export default DashboardPage
