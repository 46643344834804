/* eslint-disable import/prefer-default-export */
import { TenantConfig } from '../../objects/tenantConfig'
import nowFinanceLogo from '../../assets/now_finance_logo.png'
import nowFinanceLogoWhite from '../../assets/now_finance_logo_white.png'

export const config: TenantConfig = {
  tenantName: 'Now Finance',
  tenantLogo: nowFinanceLogo,
  tenantLogoWhite: nowFinanceLogoWhite,
  referenceHeader: 'Customer ID',
}
