// eslint-disable-next-line import/prefer-default-export
import fetchBackofficeAPI from '../../utils/NetworkUtils/fetch'
import UserSummaryDto from '../../objects/userSummaryDto'

export const types = {
  // User list
  REQUEST_USERS: 'REQUEST_USERS',
  RECEIVE_USERS: 'RECEIVE_USERS',
  RECEIVE_USERS_ERROR: 'RECEIVE_USERS_ERROR',

  // Selected user
  REQUEST_USER: 'REQUEST_USER',
  RECEIVE_USER: 'RECEIVE_USER',
  RECEIVE_USER_ERROR: 'RECEIVE_USER_ERROR',
  CLEAR_SELECTED_USER: 'CLEAR_SELECTED_USER',
}

/**
 * Request the user list
 */
function requestUsers() {
  return {
    type: types.REQUEST_USERS,
  }
}

/**
 * Receive the user list
 * @param users The list of users
 */
function receiveUsers(users: UserSummaryDto[]) {
  return {
    type: types.RECEIVE_USERS,
    users,
  }
}

/**
 * An error occurred when receiving users
 */
function receiveUsersError() {
  return {
    type: types.RECEIVE_USERS_ERROR,
  }
}

/**
 * Begin fetching the user list
 */
export function fetchUsers() {
  const endpoint = '/users'

  return (dispatch) => {
    dispatch(requestUsers())
    return fetchBackofficeAPI(endpoint, 'get')
      .then((response: UserSummaryDto[]) => dispatch(receiveUsers(response)))
      .catch(() => dispatch(receiveUsersError()))
  }
}

/**
 * Request a user's details
 * @param userId The user we are requesting
 */
function requestUser(userId: string) {
  return {
    type: types.REQUEST_USER,
    userId,
  }
}

/**
 * Receive the user's details
 * @param userId The user we are requesting
 * @param user The user's details
 */
function receiveUser(userId: string, user: UserSummaryDto) {
  return {
    type: types.RECEIVE_USER,
    userId,
    user,
  }
}

/**
 * An error occurred when receiving the user's details
 * @param userId The user we are requesting
 */
function receiveUserError(userId: string) {
  return {
    type: types.RECEIVE_USER_ERROR,
    userId,
  }
}

/**
 * Begin fetching a user's details
 * @param userId The user we are requesting
 */
export function fetchUser(userId: string) {
  const endpoint = `/users/${encodeURIComponent(userId)}`

  return (dispatch) => {
    dispatch(requestUser(userId))
    return fetchBackofficeAPI(endpoint, 'get')
      .then((response: UserSummaryDto) => dispatch(receiveUser(userId, response)))
      .catch(() => dispatch(receiveUserError(userId)))
  }
}

/**
 * Clear out the currently selected user
 * @param userId The user we are requesting
 */
export function clearSelectedUser(userId: string) {
  return {
    type: types.CLEAR_SELECTED_USER,
    userId,
  }
}
