import {
  AppBar,
  Box, Grid, Hidden, IconButton, useMediaQuery, useTheme,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import MenuIcon from '@mui/icons-material/Menu'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import ReducerRootState from '../../objects/reducerRootState'
import LoginButton from '../LoginButton'
import LogoutButton from '../LogoutButton'
import { openAppDrawer } from '../../services/NavigationService'

const ClickableGrid = styled(Grid)`
  &:hover {
    cursor: pointer;
  }
`

function PageHeader() {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const tenantConfig = useSelector((state: ReducerRootState) => state.config.config)
  const customerGroup = useSelector((state: ReducerRootState) => state.environment.customerGroup)
  const customerGroupConfig = useSelector((state: ReducerRootState) => state.environment.customerGroupConfig)
  const { loggedIn } = useSelector((state: ReducerRootState) => state.account)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const onLogoClicked = () => {
    navigate('')
  }
  return (

    <div>
      <AppBar position="fixed" elevation={isMobile ? 2 : 0} style={{ backgroundColor: 'transparent', pointerEvents: 'none' }}>
        <Box mt={1} mb={1}>
          <Grid container spacing={isMobile ? 0 : 2}>
            <Grid item xs={false} md={1} />
            <Hidden mdUp>
              <Grid container item xs={2} md={false} style={{ display: 'flex' }} alignItems="center" justifyContent="center">
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => { dispatch(openAppDrawer()) }}>
                  <MenuIcon style={{ color: 'white' }} />
                </IconButton>
              </Grid>
            </Hidden>
            <ClickableGrid
              container
              item
              xs={8}
              md={4}
              style={{ display: 'flex', pointerEvents: 'auto' }}
              alignItems="center"
              justifyContent={isMobile ? 'center' : 'flex-start'}
              onClick={() => { onLogoClicked() }}
            >
              <img src={customerGroup.length > 0 ? customerGroupConfig.logo : tenantConfig.tenantLogo} height="48px" alt="tenant logo" />
            </ClickableGrid>
            <Hidden smDown>
              <Grid container item xs={2} md={6} style={{ display: 'flex' }} alignItems="center" justifyContent={isMobile ? 'center' : 'flex-end'}>
                {loggedIn
                  ? (
                      <span style={{ pointerEvents: 'auto' }}>
                        <LogoutButton />
                      </span>
                    )
                  : (
                      <span style={{ pointerEvents: 'auto' }}>
                        <LoginButton />
                      </span>
                    )}
              </Grid>
            </Hidden>

            <Grid item xs={false} md={1} />
          </Grid>
        </Box>
      </AppBar>
    </div>
  )
}

export default PageHeader
