export const theme = {
  palette: {
    primary: {
      main: '#DF0024',
    },
    secondary: {
      main: '#DF0024',
    },
    warning: {
      main: '#FFEC00',
    },
    success: {
      main: '#7FC743',
    },
    error: {
      main: '#FF0000',
    },
    pieChart: {
      blue: '#26C1C9',
      purple: '#9771DB',
      white: '#d9d9d9',
      yellow: '#FFD700',
      green: '#86D126',
    },
    doughnutChart: {
      blue: '#26C1C9',
      green: '#86D126',
    },
  },
  typography: {
    textPrimary: {
      main: '#ffffff',
    },

  },
}

export default theme
