import { Box, Button, Grid } from '@mui/material'
import { Add } from '@mui/icons-material'

export default function Toolbar(props: { setAddUserOpen: (value: boolean) => void }) {
  const { setAddUserOpen } = props

  return (
    <Box mt={4} mb={4}>
      <Box mb={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} alignContent="flex-end">
            <Button
              variant="contained"
              style={{ borderRadius: '24px', color: 'white' }}
              color="primary"
              startIcon={<Add />}
              onClick={() => setAddUserOpen(true)}
            >
              Add User

            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
