import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { localLogout, login } from '../../services/AccountService'

export default function AuthTokenBroker() {
  const {
    isAuthenticated,
    isLoading,
    user,
    getAccessTokenSilently,
  } = useAuth0()
  const dispatch = useDispatch()

  useEffect(() => {
    if (isLoading) return

    if (!isAuthenticated) {
      dispatch(localLogout())
      return
    }

    void getAccessTokenSilently({
      authorizationParams: {
        audience: 'https://brykid.brykgroup.ai/',
        scope: 'read:session_results read:users manage:users read:session_events read:session_risks',
      },
    })
      .then(accessToken => dispatch(login(accessToken)))
  }, [isLoading, isAuthenticated, user?.sub])

  return null
}
