import { Pie } from 'react-chartjs-2'
import { chartDto, statisticUserDto } from '../../objects/chartDto'
import theme from '../../themes/bryk'

interface PieChartProps {
  pieChartData: statisticUserDto | null
}

function PieChart(props: PieChartProps) {
  const { pieChartData } = props
  const sessionResultsInfo = {
    labels: ['Successful Users', 'Partial Users',
      'Failed Users', 'Successful Rate', 'Partilal Rate', 'Failed Rate',
      'Not Started User', 'Not Started Rate'],

    datasets: [
      {
        backgroundColor: [
          theme.palette.pieChart.green,
          theme.palette.pieChart.yellow,
          theme.palette.pieChart.blue,
          theme.palette.pieChart.green,
          theme.palette.pieChart.yellow,
          theme.palette.pieChart.blue,
          theme.palette.pieChart.purple,
          theme.palette.pieChart.gray,
        ],
        data: [
          pieChartData?.successfulUser, pieChartData?.partialSuccessUser,
          pieChartData?.failedUser, pieChartData?.successfulRate?.toFixed(2), pieChartData?.partialSuccessRate.toFixed(2),
          pieChartData?.failedRate?.toFixed(2), pieChartData?.notStartedUser, pieChartData?.notStartedRate?.toFixed(2),
        ],
      },
    ],
  }
  return (

    <Pie
      options={{
        plugins: {
          legend: {
            position: 'bottom',
            align: 'start',
            display: true,
            labels: {
              color: 'white',
              padding: 14,

            },
          },

        },
      }}
      data={sessionResultsInfo}
    />
  )
}
export default PieChart
