import {
  Box, Card, CardContent, Typography,
} from '@mui/material'
import { Dvs } from '../../../objects/sessionResultsDto'
import '../../../styles/sessionDetails.css'
import getCustomiseString from '../../../utils/CustomiseUtils/customiseString'

interface DVSAttemptsTabProps {
  dvsAttempts: Dvs[] | undefined
}

function DVSAttemptsTab(props: DVSAttemptsTabProps) {
  const { dvsAttempts } = props
  const invalidCardNumberStates = ['']
  return (
    <Box mt={2}>
      {((typeof dvsAttempts === 'undefined' || dvsAttempts.length === 0))
      && (
        <Box mt={6}>
          <Typography variant="body1">
            There is no DVS attempts for this session
          </Typography>
        </Box>
      )}
      {dvsAttempts && dvsAttempts.length > 0 && dvsAttempts.map((attempt, index) => (
        <Box mb={4} key={attempt.verification.requestDate}>
          <Card className="container">
            <CardContent className="card-content">
              <Box mb={2}>
                <Typography variant="h6">
                  <b>
                    Attempt
                    {' '}
                    {dvsAttempts.length - index}
                  </b>
                </Typography>
              </Box>
              {attempt?.verification?.requestDate && (
                <>
                  <Typography variant="body1">{`Request Date: ${attempt.verification.requestDate}`}</Typography>
                  <Typography variant="body1">{`Verification Reference: ${attempt.verification.verificationReference}`}</Typography>
                  <Typography variant="body1">{`Verification Success: ${attempt.verification.verificationSuccess}`}</Typography>
                  <Typography variant="body1">{`Verification Partial Success: ${attempt.verification.verificationPartialSuccess}`}</Typography>
                  <Typography variant="body1">{`Verification Date: ${attempt.verification.verificationDate}`}</Typography>
                  <Box mt={2}>
                    <Typography variant="body1"><b>Details</b></Typography>
                    <Typography variant="body1">{`Name: ${attempt.verification.details.name.given} ${attempt.verification.details.name.family}`}</Typography>
                    <Typography variant="body1">{`Date of Birth: ${attempt.verification.details.dateofbirth}`}</Typography>
                  </Box>
                  <Box mt={2}>
                    <Typography variant="body1"><b>Validated</b></Typography>
                    <Typography variant="body1">{`Name: ${attempt.verification.validated.name}`}</Typography>
                    <Typography variant="body1">{`Address: ${attempt.verification.validated.address}`}</Typography>
                    <Typography variant="body1">{`Date of Birth: ${attempt.verification.validated.dateofbirth}`}</Typography>
                  </Box>
                  <Box mt={2}>
                    <Typography variant="body1"><b>Sources</b></Typography>
                    {attempt.verification.sources.map((source, sourceIndex) => (
                      <Box key={source.data.number} mt={2}>
                        <Typography variant="body1">
                          Source
                          {' '}
                          {sourceIndex + 1}
                        </Typography>
                        <Typography variant="body1">{`Name: ${source.name}`}</Typography>
                        <Typography variant="body1">{`Success: ${source.success}`}</Typography>
                        <Typography variant="body1">{`Error: ${source.error}`}</Typography>
                        {source.notes && (
                          <Typography variant="body1">{`Notes: ${source.notes}`}</Typography>
                        )}
                        <Typography variant="body1">{`State: ${source.data.state}`}</Typography>
                        <Typography variant="body1">{`Licence ID: ${source.data.number}`}</Typography>
                        {invalidCardNumberStates.includes(source.data.state)
                          ? <Typography variant="body1">Card Number: N/A</Typography>
                          : (<Typography variant="body1">{`Card Number: ${getCustomiseString(source.data.cardNumber)}`}</Typography>)}
                      </Box>
                    ))}
                  </Box>
                </>
              )}
              {attempt?.verification?.error && (
                <>
                  <Box mt={6}>
                    <Typography variant="body1">
                      {`DVS Error Message: ${attempt.verification.message}`}
                    </Typography>
                  </Box>
                </>
              )}
            </CardContent>
          </Card>
        </Box>
      ))}
    </Box>
  )
}

export default DVSAttemptsTab
