import { types } from './actions'

const initialState = {
  theme: {},
}

export default function themeReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_THEME:
      return { ...state, theme: action.theme }
    default:
      return state
  }
}
