import ConfigState from '../../objects/configState'
import { types } from './actions'

const initialState: ConfigState = {
  config: {
    tenantName: '',
    tenantLogo: '',
    tenantLogoWhite: '',
    referenceHeader: '',
  },
  navigationItems: [],
}

export default function configReducer(state: ConfigState = initialState, action): ConfigState {
  switch (action.type) {
    case types.SET_CONFIG:
      return { ...state, config: action.config }
    case types.SET_NAVIGATION:
      return { ...state, navigationItems: action.navigationItems }
    default:
      return state
  }
}
