import { StoreRegistry } from '../../stores'
import { getTenantForDevelopment, getTenantForProduction } from '../../utils/TenantUtils'
import * as tenantThemes from '../../themes'
import pepperMoneyLogo from '../../assets/customer-group/pepper_red_logo.png'
import nowFinanceLogo from '../../assets/now_finance_logo.png'

export const types = {
  INITIAL_SETUP: 'INITIAL_SETUP',
  SET_NODE_ENV: 'SET_NODE_ENV',
  SET_ENVIRONMENT: 'SET_ENVIRONMENT',
  SET_TENANT: 'SET_TENANT',
  SET_THEME: 'SET_THEME',
  COMPLETE_INITIAL_SETUP: 'COMPLETE_INITIAL_SETUP',
  SET_CUSTOMER_GROUP: 'SET_CUSTOMER_GROUP',
  CLEAR_CUSTOMER_GROUP: 'CLEAR_CUSTOMER_GROUP',
}

export function initialSetup(nodeEnv: string, environment: string, tenant: string) {
  return {
    type: types.INITIAL_SETUP,
    nodeEnv,
    environment,
    tenant,
  }
}

export function setNodeEnv(nodeEnv: string) {
  return {
    type: types.SET_NODE_ENV,
    nodeEnv,
  }
}

export function setTenant(tenant: string) {
  return {
    type: types.SET_TENANT,
    tenant,
  }
}

export function setEnvironment(environment: string) {
  return {
    type: types.SET_ENVIRONMENT,
    environment,
  }
}

export function setTheme(theme: string[]) {
  return {
    type: types.SET_THEME,
    theme,
  }
}

export function completeInitialSetup() {
  return {
    type: types.COMPLETE_INITIAL_SETUP,
  }
}

export async function setupEnvironment() {
  const { store } = StoreRegistry
  let tenant = ''
  const urlParams = new URLSearchParams(window.location.search)

  if (import.meta.env.NODE_ENV === 'production') {
    tenant = getTenantForProduction()
  }
  else {
    tenant = getTenantForDevelopment(urlParams)
  }

  const environment = import.meta.env.VITE_ENVIRONMENT || ''
  store.dispatch(initialSetup(import.meta.env.NODE_ENV ?? 'development', environment, tenant))
  store.dispatch(completeInitialSetup())
}

export function setCustomerGroup(customerGroup: string) {
  let newCustomerGroupConfig = {
    logo: '',
    backgroundColor: '',
  }
  if (customerGroup?.toLocaleLowerCase() === 'pepper_money') {
    newCustomerGroupConfig = {
      logo: pepperMoneyLogo,
      backgroundColor: tenantThemes.PepperMoneyTheme.default.palette.secondary.main,
    }
  }
  else if (customerGroup?.toLocaleLowerCase() === 'now_finance') {
    newCustomerGroupConfig = {
      logo: nowFinanceLogo,
      backgroundColor: tenantThemes.NowFinanceTheme.default.palette.primary.main,
    }
  }
  return {
    type: types.SET_CUSTOMER_GROUP,
    customerGroup,
    newCustomerGroupConfig,
  }
}

export function clearCustomerGroup() {
  return {
    type: types.CLEAR_CUSTOMER_GROUP,
  }
}
