import {
  Box, Card, CardContent, Typography,
} from '@mui/material'
import { Liveness } from '../../../objects/sessionResultsDto'
import '../../../styles/sessionDetails.css'
import getCustomiseString from '../../../utils/CustomiseUtils/customiseString'

interface LivenessAttemptsTabProps {
  livenessAttempts: Liveness[] | undefined
}

function LivenessAttemptsTab(props: LivenessAttemptsTabProps) {
  const { livenessAttempts } = props
  return (
    <Box mt={2}>
      {((typeof livenessAttempts === 'undefined' || livenessAttempts.length === 0))
      && (
        <Box mt={6}>
          <Typography variant="body1">
            There is no liveness attempts for this session
          </Typography>
        </Box>
      )}
      {livenessAttempts && livenessAttempts.length > 0 && livenessAttempts.map((attempt, index) => (
        <Box mb={4} key={attempt.faceMapId}>
          <Card className="container">
            <CardContent className="card-content">
              <Box>
                <Box mb={2}>
                  <Typography variant="h6">
                    <b>
                      Attempt
                      {' '}
                      {livenessAttempts.length - index}
                    </b>
                  </Typography>
                </Box>
                <Typography variant="body1">{`Audit Success: ${getCustomiseString(attempt.auditSuccess)}`}</Typography>
                <Typography variant="body1">{`Facemap ID: ${getCustomiseString(attempt.faceMapId)}`}</Typography>
                <Typography variant="body1">{`Liveness Status: ${getCustomiseString(attempt.livenessStatus.replace(/_/g, ' '))}`}</Typography>
                <Typography variant="body1">{`Session Status: ${getCustomiseString(attempt.sessionStatus.replace(/_/g, ' '))}`}</Typography>
                <Typography variant="body1">{`Success: ${getCustomiseString(attempt.success)}`}</Typography>
                <Box mb={2}>
                  {' '}
                  <Typography variant="body1">
                    {`Timestamp: 
                    ${new Date(attempt.timestamp * 1000).toLocaleDateString('en-AU', { day: 'numeric', month: 'numeric', year: 'numeric' })} 
                    ${new Date(attempt.timestamp * 1000).toLocaleTimeString('en-AU')}`}
                  </Typography>
                </Box>
                {attempt.auditTrialImage.length > 0
                  ? (
                      <img
                        alt="idImage"
                        src={`data:image/jpeg;base64,${attempt.auditTrialImage}`}
                        width="15%"
                        height="15%"
                      />
                    )
                  : <></>}
              </Box>
            </CardContent>
          </Card>
        </Box>
      ))}
    </Box>
  )
}

export default LivenessAttemptsTab
