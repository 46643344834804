import React from 'react'
import {
  Box, Grid, useTheme, useMediaQuery,
} from '@mui/material'

function PageLayout(props) {
  const { children } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box display="flex" flexDirection="column" minHeight={isMobile ? 'calc(100vh - 64px)' : 'calc(100vh - 128px)'}>
      <Grid container style={{ flex: 1 }}>
        <Grid item xs={1} md={1} lg={1} xl={2} />
        <Grid item xs={10} md={10} lg={10} xl={8}>
          {children}
        </Grid>
        <Grid item xs={1} md={1} lg={1} xl={2} />
      </Grid>
    </Box>
  )
}

export default PageLayout
