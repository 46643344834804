import { useEffect, useState } from 'react'
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,
} from '@mui/material'
import { useDispatch } from 'react-redux'
import fetchBackofficeAPI from '../../utils/NetworkUtils/fetch'
import { fetchUsers } from '../../services/UserManagementService'

export default function AddUserDialog(props: { open: boolean, onClose: () => void }) {
  const {
    open,
    onClose,
  } = props
  const dispatch = useDispatch()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [submitting, setSubmitting] = useState(false)

  function emptyErrors() {
    return {
      generic: '',
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
    }
  }

  const [errors, setErrors] = useState(emptyErrors)

  // We do it here instead of on close to avoid a flash of content during the closing animation.
  useEffect(() => {
    if (open) {
      setName('')
      setEmail('')
      setPassword('')
      setConfirmPassword('')
      setErrors(emptyErrors())
    }
  }, [open])

  function close() {
    onClose()
  }

  function create() {
    const newErrors = emptyErrors()

    if (name.length === 0) {
      newErrors.name = 'User needs a name'
    }

    // Verify fields
    if (!/^\S+@\S+$/.test(email)) {
      newErrors.email = 'Not a valid email address'
    }

    if (password.length < 8) {
      newErrors.password = 'Password not long enough'
    }

    if (password !== confirmPassword) {
      newErrors.confirmPassword = 'Password does not match'
    }

    setErrors(newErrors)

    // If there are no errors
    if (!Object.values(newErrors)
      .find(e => !!e)) {
      setSubmitting(true)
      fetchBackofficeAPI('/users', 'POST', JSON.stringify({
        email,
        password,
        name,
      }))
        .then((res) => {
          if (res.success) {
            dispatch(fetchUsers())
            onClose()
          }
          else {
            newErrors.generic = res.error
            setErrors({
              ...newErrors,
              generic: res.error,
            })
          }
        }).finally(() => setSubmitting(false))
    }
  }

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>Add New User</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter the new user&apos;s details below.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Name"
          type="text"
          fullWidth
          value={name}
          onChange={el => setName(el.currentTarget.value)}
          error={!!errors.name}
          helperText={errors.name}
          disabled={submitting}
        />
        <TextField
          margin="dense"
          label="Email Address"
          type="email"
          fullWidth
          value={email}
          onChange={el => setEmail(el.currentTarget.value)}
          error={!!errors.email}
          helperText={errors.email}
          disabled={submitting}
        />
        <TextField
          margin="dense"
          label="Password"
          type="password"
          fullWidth
          value={password}
          onChange={el => setPassword(el.currentTarget.value)}
          error={!!errors.password}
          helperText={errors.password}
          disabled={submitting}
        />
        <TextField
          margin="dense"
          label="Confirm Password"
          type="password"
          fullWidth
          value={confirmPassword}
          onChange={el => setConfirmPassword(el.currentTarget.value)}
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword}
          disabled={submitting}
        />
        {errors.generic && (
          <DialogContentText color="error">{errors.generic}</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary" disabled={submitting}>Cancel</Button>
        <Button onClick={create} color="primary" disabled={submitting}>Create</Button>
      </DialogActions>
    </Dialog>
  )
}
