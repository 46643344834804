import {
  Box, Card, CardContent, Typography,
} from '@mui/material'
import { IdScan, idScanAttemptsSeparated } from '../../../objects/sessionResultsDto'
import '../../../styles/sessionDetails.css'
import getCustomiseString from '../../../utils/CustomiseUtils/customiseString'

interface IDScanAttemptsTabProps {
  idScanAttempts: idScanAttemptsSeparated | undefined
}

function IDScanAttemptsTab(props: IDScanAttemptsTabProps) {
  const { idScanAttempts } = props
  const invalidCardNumberStates = ['']
  return (
    <>
      <Box mt={2}>
        {(typeof idScanAttempts?.back === 'undefined' || idScanAttempts.back.length === 0)
        && (
          <Box mt={6}>
            <Typography variant="body1">
              There is no Back ID Scan attempts for this session
            </Typography>
          </Box>
        )}
        {idScanAttempts?.front && idScanAttempts?.back.length > 0 && idScanAttempts.back.map((attempt, index) => (
          <Box mb={4} key={attempt.resultBlob}>
            <Card className="container">
              <CardContent className="card-content">
                <Box mb={2}>
                  <Typography variant="h6">
                    <b>
                      Backside-Attempt
                      {' '}
                      {idScanAttempts.back.length - index}
                    </b>
                  </Typography>
                </Box>
                <Typography variant="body1">{`Facematch Status: ${getCustomiseString(attempt.faceMatchStatus)}`}</Typography>
                <Typography variant="body1">{`Full ID Status: ${getCustomiseString(attempt.fullIdStatus.replace(/_/g, ' '))}`}</Typography>
                <Typography variant="body1">{`Match Level: ${getCustomiseString(attempt.matchLevel.replace(/_/g, ' '))}`}</Typography>
                <Typography variant="body1">{`Recognised ID Type: ${attempt.didMatchIDScanToOCRTemplate ? 'Yes' : 'No'}`}</Typography>
                <Typography variant="body1">{`Spoof Status: ${getCustomiseString(attempt.spoofStatus.replace(/_/g, ' '))}`}</Typography>
                <Typography variant="body1">{`Tampering Status: ${getCustomiseString(attempt.faceOnDocumentStatus.replace(/_/g, ' '))}`}</Typography>
                <Typography variant="body1">{`Success: ${getCustomiseString(attempt.success)}`}</Typography>
                <Box className="imageView">
                  {attempt.originalIdImage?.length > 0
                    ? (
                        <img
                          alt="idImage"
                          src={`data:image/jpeg;base64,${attempt.originalIdImage}`}
                          width="400px"
                          height="auto"
                        />
                      )
                    : <></>}
                  {attempt.idImage?.length > 0
                    ? (
                        <img
                          alt="idImage"
                          src={`data:image/jpeg;base64,${attempt.idImage}`}
                          width="400px"
                          height="auto"
                        />
                      )
                    : <></>}
                </Box>
                {attempt.reformattedOcrResults && (
                  <Box mt={2}>
                    <Typography variant="body1"><b>OCR Result</b></Typography>
                    <Typography variant="body1">{`Name: ${attempt.reformattedOcrResults?.name?.first} ${attempt.reformattedOcrResults?.name?.last}`}</Typography>
                    <Typography variant="body1">{`Date of Birth: ${getCustomiseString(attempt.reformattedOcrResults?.dateOfBirth)}`}</Typography>
                    {attempt.reformattedOcrResults?.idList?.map((id, idIndex) => (
                      <Box key={id.idNumber} mt={2}>
                        <Typography variant="body1"><b>{`ID: ${idIndex + 1}`}</b></Typography>
                        <Typography variant="body1">{`ID Type: ${getCustomiseString(id.idType)}`}</Typography>
                        <Typography variant="body1">{`ID Number: ${getCustomiseString(id.idNumber)}`}</Typography>
                        <Typography variant="body1">{`Card Number: ${getCustomiseString(id.cardNumber)}`}</Typography>
                        <Typography variant="body1">{`Registration Place: ${getCustomiseString(id.registrationPlace)}`}</Typography>
                      </Box>
                    ))}
                    {attempt.reformattedOcrResults?.addressList?.map((address, addressIndex) => (
                      <Box key={address.firstLine} mt={2}>
                        <Typography variant="body1"><b>{`Address: ${addressIndex + 1}`}</b></Typography>
                        <Typography variant="body1">{`${getCustomiseString(address.firstLine)}`}</Typography>
                        <Typography variant="body1">{`${address.suburb} ${address.state} ${address.postcode} ${address.countryCode}`}</Typography>
                      </Box>
                    ))}
                  </Box>
                )}
                <Typography variant="body1">
                  {`Timestamp:
                  ${new Date(attempt.timestamp * 1000).toLocaleDateString('en-AU', { day: 'numeric', month: 'numeric', year: 'numeric' })}
                  ${new Date(attempt.timestamp * 1000).toLocaleTimeString('en-AU')}`}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        ))}

      </Box>
      <Box mt={2}>
        {(typeof idScanAttempts?.front === 'undefined' || idScanAttempts.front.length === 0)
        && (
          <Box mt={6}>
            <Typography variant="body1">
              There is no Front ID Scan attempts for this session
            </Typography>
          </Box>
        )}
        {idScanAttempts?.front && idScanAttempts?.front.length > 0 && idScanAttempts.front.map((attempt, index) => (
          <Box mb={4} key={attempt.resultBlob}>
            <Card className="container">
              <CardContent className="card-content">
                <Box mb={2}>
                  <Typography variant="h6">
                    <b>
                      Frontside-Attempt
                      {' '}
                      {idScanAttempts.front.length - index}
                    </b>
                  </Typography>
                </Box>
                <Box mt={2}>
                  <Typography variant="body1">{`Facematch Status: ${getCustomiseString(attempt.faceMatchStatus)}`}</Typography>
                  <Typography variant="body1">{`Full ID Status: ${getCustomiseString(attempt.fullIdStatus.replace(/_/g, ' '))}`}</Typography>
                  <Typography variant="body1">{`Match Level: ${getCustomiseString(attempt.matchLevel.replace(/_/g, ' '))}`}</Typography>
                  <Typography variant="body1">{`Recognised ID Type: ${attempt.didMatchIDScanToOCRTemplate ? 'Yes' : 'No'}`}</Typography>
                  <Typography variant="body1">{`Spoof Status: ${getCustomiseString(attempt.spoofStatus.replace(/_/g, ' '))}`}</Typography>
                  <Typography variant="body1">{`Tampering Status: ${getCustomiseString(attempt.faceOnDocumentStatus.replace(/_/g, ' '))}`}</Typography>
                  <Typography variant="body1">{`Success: ${getCustomiseString(attempt.success)}`}</Typography>
                </Box>
                {attempt.reformattedOcrResults && (
                  <Box mt={2}>
                    <Typography variant="body1"><b>OCR Result</b></Typography>
                    <Typography variant="body1">{`Name: ${attempt.reformattedOcrResults?.name?.first} ${attempt.reformattedOcrResults?.name?.last}`}</Typography>
                    <Typography variant="body1">{`Date of Birth: ${getCustomiseString(attempt.reformattedOcrResults?.dateOfBirth)}`}</Typography>
                    {attempt.reformattedOcrResults?.idList?.map((id, idIndex) => (
                      <Box key={id.idNumber} mt={2}>
                        <Typography variant="body1"><b>{`ID: ${idIndex + 1}`}</b></Typography>
                        <Typography variant="body1">{`ID Type: ${getCustomiseString(id.idType)}`}</Typography>
                        <Typography variant="body1">{`ID Number: ${getCustomiseString(id.idNumber)}`}</Typography>
                        { invalidCardNumberStates.includes(id.registrationPlace)
                          ? <Typography variant="body1">Card Number: N/A</Typography>
                          : (<Typography variant="body1">{`Card Number: ${id?.cardNumber}`}</Typography>)}
                        <Typography variant="body1">{`Registration Place: ${getCustomiseString(id.registrationPlace)}`}</Typography>
                      </Box>
                    ))}
                    {attempt.reformattedOcrResults?.addressList?.map((address, addressIndex) => (
                      <Box key={address.firstLine} mt={2}>
                        <Typography variant="body1"><b>{`Address: ${addressIndex + 1}`}</b></Typography>
                        <Typography variant="body1">{`${getCustomiseString(address.firstLine)}`}</Typography>
                        <Typography variant="body1">{`${address.suburb} ${address.state} ${address.postcode} ${address.countryCode}`}</Typography>
                      </Box>
                    ))}
                  </Box>
                )}
                <Box className="imageView">
                  {attempt.originalIdImage?.length > 0
                    ? (
                        <img
                          alt="idImage"
                          src={`data:image/jpeg;base64,${attempt.originalIdImage}`}
                          style={{ maxWidth: 450 }}
                        />
                      )
                    : <></>}
                  {attempt.idImage?.length > 0
                    ? (
                        <img
                          alt="idImage"
                          src={`data:image/jpeg;base64,${attempt.idImage}`}
                          style={{ maxWidth: 450 }}
                        />
                      )
                    : <></>}
                  {attempt.face?.length > 0
                    ? (
                        <img
                          alt="idImage"
                          src={`data:image/jpeg;base64,${attempt.face}`}
                          style={{ maxWidth: 200 }}
                        />
                      )
                    : <></>}

                </Box>

                <Typography variant="body1">
                  {`Timestamp:
                ${new Date(attempt.timestamp * 1000).toLocaleDateString('en-AU', { day: 'numeric', month: 'numeric', year: 'numeric' })}
                ${new Date(attempt.timestamp * 1000).toLocaleTimeString('en-AU')}`}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        ))}

      </Box>
    </>
  )
}

export default IDScanAttemptsTab
