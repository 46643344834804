import {
  Avatar,
  Paper,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@mui/material'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import UserSummaryDto from '../../objects/userSummaryDto'
import '../../styles/userManagement.css'

const ClickableTableRow = styled(TableRow)`
  &:hover {
    cursor: pointer;
  }
`

export default function UserTable(props: { users: UserSummaryDto[] }) {
  const { users } = props
  const navigate = useNavigate()

  const userNodes = users.map(user => (
    <ClickableTableRow hover role="link" onClick={() => navigate(`/users/${user.userId}`)}>
      <TableCell className="table-cell">{user.name}</TableCell>
      <TableCell className="table-cell">{user.userId}</TableCell>
      <TableCell className="table-cell" padding="checkbox">
        <Avatar src={user.picture} />
      </TableCell>
    </ClickableTableRow>
  ))

  return (
    <TableContainer component={Paper} style={{ backgroundColor: 'transparent', color: 'white' }}>
      <Table>
        <TableHead className="table-header">
          <TableRow>
            <TableCell style={{ color: 'white' }}>
              <Typography style={{ fontWeight: 'bold', color: 'white' }}>Email</Typography>
            </TableCell>
            <TableCell style={{ color: 'white' }}>
              <Typography style={{ fontWeight: 'bold', color: 'white', textAlign: 'start' }}>User ID</Typography>
            </TableCell>
            <TableCell style={{ minWidth: '140px' }} />
          </TableRow>
        </TableHead>
        <TableBody style={{ backgroundColor: 'transparent' }}>
          {userNodes}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
