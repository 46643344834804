import NavigationState from '../../objects/navigationState'
import { types } from './actions'

const initialState: NavigationState = {
  appDrawerOpen: false,
}

export default function navigationReducer(state: NavigationState = initialState, action): NavigationState {
  switch (action.type) {
    case types.OPEN_APP_DRAWER:
      return {
        ...state, appDrawerOpen: true,
      }
    case types.CLOSE_APP_DRAWER:
      return {
        ...state, appDrawerOpen: false,
      }
    default:
      return state
  }
}
