import LoadingView from '../LoadingView'
import PageLayout from '../PageLayout'

function LandingPage() {
  return (
    <PageLayout>
      <LoadingView />
    </PageLayout>
  )
}

export default LandingPage
