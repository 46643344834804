import { NavigationItem } from '../../objects/navigationItem'

const navigationItems: NavigationItem[] = [
  {
    defaultIcon: <div />,
    hoverIcon: <div />,
    selectedIcon: <div />,
    redirectPath: '',
    itemTitle: 'Dashboard',
    highlightedColor: '#343642',
    subTab: [],
  },
  {
    defaultIcon: <div />,
    hoverIcon: <div />,
    selectedIcon: <div />,
    redirectPath: '/results',
    itemTitle: 'Results',
    highlightedColor: '#343642',
    subTab: [],
  },
]

export default navigationItems
