import { useTheme } from '@mui/material/styles'
import {
  Box, Hidden, Drawer, IconButton, List,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch, useSelector } from 'react-redux'
import NavigationItem from './NavigationItem'
import navigationItems from './navigationItemsConstants'
import { closeAppDrawer } from '../../services/NavigationService'
import AppDrawerItem from './AppDrawerItem'
import ReducerRootState from '../../objects/reducerRootState'
import LoginButton from '../LoginButton'
import LogoutButton from '../LogoutButton'
import { selectUserScopes } from '../../services/AccountService/selectors'
import '../../styles/navigationBar.css'

function NavigationBar() {
  const theme = useTheme()
  const { appDrawerOpen } = useSelector((state: ReducerRootState) => state.navigation)
  const { loggedIn } = useSelector((state: ReducerRootState) => state.account)
  const userScopes = useSelector(selectUserScopes)
  const dispatch = useDispatch()

  return (
    <div className="top_header">
      <Hidden smDown>
        <Box display="flex" style={{ height: '64px' }}>
          <Box flexDirection="row" display="flex" flex="1">
            <Hidden mdDown>
              <Box flex={1} />
            </Hidden>

            <Box position="relative" display="flex" flex={10} flexDirection="column" justifyContent="center" alignItems="center" color="white">
              <Box display="flex" flexDirection="row" flex="1">
                {navigationItems
                  .filter(({ requiredScopes }) => requiredScopes
                    .filter(permission => !userScopes.includes(permission))
                    .length === 0)
                  .map(navigationItem => (
                    <NavigationItem
                      key={navigationItem.itemTitle}
                      defaultIcon={navigationItem.defaultIcon}
                      hoverIcon={navigationItem.hoverIcon}
                      selectedIcon={navigationItem.selectedIcon}
                      redirectPath={navigationItem.redirectPath}
                      highlightedColor={theme.palette.primary.main}
                      itemTitle={navigationItem.itemTitle}
                      subTab={navigationItem.subTab}
                    />
                  ))}
              </Box>
            </Box>

            <Hidden mdDown>
              <Box flex={1} />
            </Hidden>
          </Box>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Drawer anchor="left" open={appDrawerOpen} onClose={() => { dispatch(closeAppDrawer()) }} style={{ width: '100vw' }}>
          <Box display="flex" flexDirection="column" pt={1} pb={1} style={{ width: '100vw', height: '100vh', backgroundColor: theme.palette.primary.main }}>
            <Box ml={2} mr={2} display="flex">
              <IconButton style={{ color: theme.palette.common.white }} aria-label="close-menu" onClick={() => { dispatch(closeAppDrawer()) }}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="flex-end">
              {!loggedIn
                ? (
                    <LoginButton />
                  )
                : (
                    <LogoutButton />
                  ) }
            </Box>

            <Box flex={1} display="flex">
              <List style={{ flex: 1 }} disablePadding>
                {navigationItems
                  .filter(({ requiredScopes }) => requiredScopes
                    .filter(permission => !userScopes.includes(permission))
                    .length === 0)
                  .map(navigationItem => (
                    <AppDrawerItem
                      key={navigationItem.itemTitle}
                      defaultIcon={navigationItem.defaultIcon}
                      hoverIcon={navigationItem.hoverIcon}
                      selectedIcon={navigationItem.selectedIcon}
                      redirectPath={navigationItem.redirectPath}
                      highlightedColor={theme.palette.primary.dark}
                      itemTitle={navigationItem.itemTitle}
                      subTab={navigationItem.subTab}
                    />
                  ))}
              </List>
            </Box>
          </Box>

        </Drawer>
      </Hidden>
    </div>

  )
}

export default NavigationBar
