import {
  Box, Card, CardContent, CardHeader, Typography,
} from '@mui/material'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import { BrowserInfo } from '../../../objects/browserInfo'
import '../../../styles/sessionDetails.css'
import getCustomiseString from '../../../utils/CustomiseUtils/customiseString'

interface BrowserInfoTabProps {
  browserInfo: BrowserInfo | undefined
  sessionLogs: any | null
}

function BrowserInfoTab(props: BrowserInfoTabProps) {
  const containerStyle = {
    width: '400px',
    height: '400px',
  }

  const { browserInfo, sessionLogs } = props

  const { isMapsLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAzJNgqn9gzVbHGPJcxdjVBNRzMxsc9d0M',
    libraries: ['places'],
  })

  return (

    <Box mt={2}>
      {(typeof browserInfo === 'undefined')
      && (
        <Box mt={6}>
          <Typography variant="body1">
            There is no Browser Info
            for this session
          </Typography>

        </Box>
      )}
      {(browserInfo) && (
        <>
          <Card className="container">
            <CardContent className="card-content">
              <Typography variant="body1">{`Browser Name: ${getCustomiseString(browserInfo.browser.name)}`}</Typography>
              <Typography variant="body1">{`Browser Version: ${getCustomiseString(browserInfo.browser.version)}`}</Typography>
              <Typography variant="body1">{`User Device Model: ${getCustomiseString(browserInfo.device.model)}`}</Typography>
              <Typography variant="body1">{`User Device Type: ${getCustomiseString(browserInfo.device.type)}`}</Typography>
            </CardContent>
            <CardContent className="card-content">
              <Typography variant="body1">{`OS Name: ${getCustomiseString(browserInfo.os.name)}`}</Typography>
              <Typography variant="body1">{`OS Version: ${getCustomiseString(browserInfo.os.version)}`}</Typography>
              <Typography variant="body1">{`General Info: ${getCustomiseString(browserInfo.general)}`}</Typography>
              <Typography variant="body1">{`Type: ${getCustomiseString(browserInfo.type)}`}</Typography>
              <Typography variant="body1">{`IP Address: ${getCustomiseString(browserInfo.ipAddress)}`}</Typography>
            </CardContent>
          </Card>
          <Card className="container">
            <CardHeader title="Location Info" style={{ display: 'flex', justifyContent: 'flex-start' }} />
            <CardContent className="card-content">
              <Typography variant="body1">{`User GEO Location Latitude: ${getCustomiseString(browserInfo?.geolocation?.gpsLatitude)} `}</Typography>
              <Typography variant="body1">{`User GEO Location Longitude:  ${getCustomiseString(browserInfo.geolocation?.gpsLongitude)}`}</Typography>
              <Typography variant="body1">{`ISP GEO Location Latitude: ${getCustomiseString(browserInfo?.geolocation?.latitude)}`}</Typography>
              <Typography variant="body1">{`ISP GEO Location Longitude:  ${getCustomiseString(browserInfo.geolocation?.longitude)}`}</Typography>
              <Typography variant="body1">{`City: ${getCustomiseString(browserInfo.geolocation?.city)}`}</Typography>
              <Typography variant="body1">{`Country: ${getCustomiseString(browserInfo.geolocation?.country)}`}</Typography>
            </CardContent>
            <CardContent className="card-content" style={{ justifyContent: 'center', display: 'flex' }}>
              {browserInfo?.geolocation.gpsLatitude
              && (
                <CardContent>
                  <Typography>User GEO Location </Typography>
                  (isMapsLoaded ?
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={{ lat: browserInfo.geolocation?.gpsLatitude, lng: browserInfo?.geolocation?.gpsLongitude }}
                    zoom={13}
                  >
                    <Marker position={{ lat: browserInfo.geolocation?.gpsLatitude, lng: browserInfo?.geolocation?.gpsLongitude }} />
                  </GoogleMap>
                  :
                  <Typography>Loading map...</Typography>
                  )
                </CardContent>
              )}

              {browserInfo?.geolocation.latitude
              && (
                <CardContent>
                  <Typography>ISP GEO Location </Typography>
                  (isMapsLoaded ?
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={{ lat: browserInfo.geolocation?.latitude, lng: browserInfo?.geolocation?.longitude }}
                    zoom={13}
                  >
                    <Marker position={{ lat: browserInfo.geolocation?.latitude, lng: browserInfo?.geolocation?.longitude }} />
                  </GoogleMap>
                  :
                  <Typography>Loading map...</Typography>
                  )
                </CardContent>
              )}
            </CardContent>

          </Card>
          <Card className="container">

            {sessionLogs && sessionLogs.logs.length > 0 && sessionLogs.logs.map(log => (
              <Box mb={4} key={log.sessionId}>
                <CardHeader title="Browser Info" style={{ display: 'flex', justifyContent: 'flex-start' }} />
                <Card className="container" title="Browser History">
                  <CardContent className="card-content" title="Browser History">
                    <Box mb={2} />
                    <>
                      <Typography variant="body1" style={{ color: 'yellow' }}>
                        {`Message : ${log.data?.msg}`}
                      </Typography>

                    </>
                  </CardContent>

                </Card>

              </Box>
            ))}
          </Card>

        </>
      )}
    </Box>
  )
}
export default BrowserInfoTab
