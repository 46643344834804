import { localLogout } from '../../services/AccountService'
import { StoreRegistry } from '../../stores'
import { navigatePtr } from '../../components/App/Routes.tsx'

export default async function fetchBackofficeAPI(endpoint, method = 'get', data: string | null = null, accept = 'application/json', contentType = 'application/json') {
  const url = `${import.meta.env.VITE_REST_BACKOFFICE_BASE_API_URL}${endpoint}`

  const { accessToken } = StoreRegistry.store.getState().account
  const now = Math.floor(Date.now() / 1000)
  if (accessToken.expireTime < now) {
    StoreRegistry.store.dispatch(localLogout())
  }

  // if (additionalHeaders) {
  //   for (let i = 0; i < additionalHeaders.length; i += 1) {
  //     myHeader.set(additionalHeaders[i].name, additionalHeaders[i].value);
  //   }
  // }

  return fetch(url, {
    method,
    headers: {
      'Accept': accept,
      'Content-Type': contentType,
      'Authorization': `Bearer ${accessToken}`,
    },
    body: data,
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json()
      } if (response.status === 401 || response.status === 403) {
        // Not the ideal "fix" but it'll do
        window.location.reload()
        throw (response)
      }
      else {
        throw (response)
      }
    })
    .catch((error) => {
      throw (error)
    })
}
