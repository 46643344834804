import { Box, Typography, Chip } from '@mui/material'
import { chartDto, statisticUserDto } from '../../objects/chartDto'

interface SessionHistoryProps {
  userStatisticsCount: statisticUserDto | null
}
function SessionHistoryCard(props: SessionHistoryProps) {
  const { userStatisticsCount } = props
  return (
    <>
      <Box display="flex">
        <Typography style={{ flexGrow: '1' }}>Successful Users:</Typography>
        <Chip
          title={(userStatisticsCount?.successfulUser?.toString())}
          label={(userStatisticsCount?.successfulUser?.toString())}
          className="card-chip"
        />

      </Box>
      <Box display="flex" mt={2}>
        <Typography style={{ flexGrow: '1' }}>Successful Rate: </Typography>
        <Chip
          title={(userStatisticsCount?.successfulRate?.toString())}
          label={(userStatisticsCount?.successfulRate?.toFixed(2))}
          className="card-chip"
        />

      </Box>
      <Box display="flex" mt={2}>
        <Typography style={{ flexGrow: '1' }}>Partial Success Users: </Typography>
        <Chip
          title={(userStatisticsCount?.partialSuccessUser?.toString())}
          label={(userStatisticsCount?.partialSuccessUser?.toString())}
          className="card-chip"
        />

      </Box>
      <Box display="flex" mt={2}>
        <Typography style={{ flexGrow: '1' }}>Partial Success Rate: </Typography>
        <Chip
          title={(userStatisticsCount?.partialSuccessRate?.toString())}
          label={(userStatisticsCount?.partialSuccessRate?.toFixed(2))}
          className="card-chip"
        />

      </Box>
      <Box display="flex" mt={2}>
        <Typography style={{ flexGrow: '1' }}>Failed Users: </Typography>
        <Chip
          title={(userStatisticsCount?.failedUser?.toString())}
          label={(userStatisticsCount?.failedUser?.toString())}
          className="card-chip"
        />

      </Box>
      <Box display="flex" mt={2}>
        <Typography style={{ flexGrow: '1' }}>Failed Rate:</Typography>
        <Chip
          title={(userStatisticsCount?.failedRate?.toString())}
          label={(userStatisticsCount?.failedRate?.toFixed(2))}
          className="card-chip"
        />
      </Box>
      <Box display="flex" mt={2}>
        <Typography style={{ flexGrow: '1' }}>Not Started Users:</Typography>
        <Chip
          title={(userStatisticsCount?.notStartedUser?.toString())}
          label={(userStatisticsCount?.notStartedUser?.toString())}
          className="card-chip"
        />
      </Box>
      <Box display="flex" mt={2}>
        <Typography style={{ flexGrow: '1' }}>Not Started Rate:</Typography>
        <Chip
          title={(userStatisticsCount?.notStartedRate?.toString())}
          label={(userStatisticsCount?.notStartedRate?.toFixed(2))}
          className="card-chip"
        />
      </Box>

    </>
  )
}
export default SessionHistoryCard
