/* eslint-disable import/prefer-default-export */
import { TenantConfig } from '../../objects/tenantConfig'
import brykLogo from '../../assets/bryk_id_logo.png'
import brykLogoWhite from '../../assets/bryk_id_logo_white.png'

export const config: TenantConfig = {
  tenantName: 'BRYK',
  tenantLogo: brykLogo,
  tenantLogoWhite: brykLogoWhite,
  referenceHeader: 'Customer ID',
}
