import * as tenantConstants from '../../constants'
import { NavigationItem } from '../../objects/navigationItem'
import { TenantConfig } from '../../objects/tenantConfig'
import StoreRegistry from '../../stores/storeRegistry'

export const types = {
  SET_CONFIG: 'SET_CONFIG',
  SET_NAVIGATION: 'SET_NAVIGATION',
}

function setConfig(config: TenantConfig) {
  return {
    type: types.SET_CONFIG,
    config,
  }
}

function setNavigation(navigationItems: NavigationItem[]) {
  return {
    type: types.SET_NAVIGATION,
    navigationItems,
  }
}

export function loadConfig(tenant: string) {
  const { store } = StoreRegistry
  let loadedTenantConfig
  let loadedTenantNavigation
  if (tenant === 'nowfinance') {
    loadedTenantConfig = tenantConstants.NowFinanceConfig.config
    loadedTenantNavigation = tenantConstants.NowFinanceConfig.navigationItems
  }
  else if (tenant === 'bryk') {
    loadedTenantConfig = tenantConstants.BrykConfig.config
    loadedTenantNavigation = tenantConstants.BrykConfig.navigationItems
  }
  else {
    loadedTenantConfig = {
      tenantName: 'Bryk',
    }
    loadedTenantNavigation = []
  }
  store.dispatch(setConfig(loadedTenantConfig))
  store.dispatch(setNavigation(loadedTenantNavigation))
}
