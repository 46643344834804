import ResultsState from '../../objects/resultsState'
import { types } from './actions'

const initialState: ResultsState = {
  isLoadingSessions: false,
  isLoadingUsers: false,
  isLoadingUsersSession: false,
  sessions: [],
  userResults: null,
  usersSession: null,
  sessionEvents: null,
  sessionLogs: null,
  totalSessions: 0,
  isSessionsError: false,
  previouslySelectedSession: '',

  isLoadingSessionResult: false,
  sessionResult: null,
  isSessionResultError: false,
}

export default function resultsReducer(state: ResultsState = initialState, action): ResultsState {
  switch (action.type) {
    case types.REQUEST_SESSIONS:
      return {
        ...state, isLoadingSessions: true, sessions: [], totalSessions: 0, isSessionsError: false,
      }
    case types.RECEIVE_SESSIONS:
      return {
        ...state, isLoadingSessions: false, sessions: action.sessions, totalSessions: action.totalSessions, isSessionsError: false,
      }
    case types.RECEIVE_SESSIONS_ERROR:
      return {
        ...state, isLoadingSessions: false, sessions: [], totalSessions: 0, isSessionsError: true,
      }
    case types.SELECT_SESSION:
      return {
        ...state, previouslySelectedSession: action.sessionId,
      }
    case types.REQUEST_SESSION_RESULT:
      return {
        ...state, isLoadingSessionResult: true, sessionResult: null, isSessionResultError: false,
      }
    case types.RECEIVE_SESSION_RESULT:
      return {
        ...state, isLoadingSessionResult: false, sessionResult: action.sessionResult, isSessionResultError: false,
      }
    case types.RECEIVE_SESSION_RESULT_ERROR:
      return {
        ...state, isLoadingSessionResult: false, sessionResult: null, isSessionResultError: true,
      }
    case types.REQUEST_USERS:
      return {
        ...state, isLoadingUsers: true, userResults: null,
      }
    case types.RECEIVE_USERS:
      return {
        ...state, isLoadingUsers: false, userResults: action.users,
      }
    case types.REQUEST_USERS_SESSION:
      return {
        ...state, isLoadingUsersSession: true, usersSession: null,
      }
    case types.REQUEST_SESSION_EVENTS:
      return {
        ...state, sessionEvents: null,
      }
    case types.RECEIVE_SESSION_EVENTS:
      return {
        ...state, sessionEvents: action.events,
      }
    case types.REQUEST_SESSION_LOGS:
      return {
        ...state, sessionLogs: null,
      }
    case types.RECEIVE_SESSION_LOGS:
      return {
        ...state, sessionLogs: action.logs,
      }
    case types.RECEIVE_USERS_SESSION:
      return {
        ...state, isLoadingUsersSession: false, usersSession: action.usersSession,
      }

    default:
      return state
  }
}
