import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Chip,
} from '@mui/material'
import { Delete } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import PageLayout from '../PageLayout'
import { clearSelectedUser, fetchUser } from '../../services/UserManagementService/actions'
import ReducerRootState from '../../objects/reducerRootState'
import { UserRequestState } from '../../objects/userManagementState'
import LoadingView from '../LoadingView'
import fetchBackofficeAPI from '../../utils/NetworkUtils/fetch'
import '../../styles/userManagement.css'

function ConfirmDeleteDialog(props: { name: string, userId: string, open: boolean, onClose: () => void }) {
  const {
    name, userId, open, onClose,
  } = props
  const navigate = useNavigate()
  const [deleting, setDeleting] = useState(false)

  const close = () => {
    if (!deleting) {
      onClose()
    }
  }

  const deleteUser = () => {
    setDeleting(true)
    fetchBackofficeAPI(`/users/${encodeURIComponent(userId)}`, 'delete')
      .then(() => {
        navigate('/users')
      })
  }

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>{`Are you sure you want to delete ${name}?`}</DialogTitle>
      {deleting && (
        <DialogContent>
          <LoadingView />
        </DialogContent>
      )}
      <DialogActions>
        <Button color="primary" onClick={close} disabled={deleting}>Cancel</Button>
        <Button color="secondary" onClick={deleteUser} disabled={deleting}>Confirm Deletion</Button>
      </DialogActions>
    </Dialog>
  )
}

function FulfilledView() {
  const user = useSelector((state: ReducerRootState) => state.userManagement.selectedUserDetails!)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const formattedLastLoginDate = moment(user?.last_login).format('DD/MM/YYYY')
  return (
    <>
      <Box mt={4}>
        <Typography variant="h5" align="center" style={{ color: 'white' }}>
          {`User : ${user.name}`}
        </Typography>

        <Button
          variant="contained"
          color="secondary"
          style={{ borderRadius: '24px', color: 'white' }}
          startIcon={<Delete />}
          onClick={() => setDeleteOpen(true)}
        >
          Delete user

        </Button>
        <Box mt={3} style={{ justifyContent: 'center', width: '100%' }}>
          <Card className="container">
            <CardContent className="user-card-content">
              <Box mb={2}>
                {' '}
                {user.picture.length > 0
                  ? (
                      <img
                        alt="idImage"
                        src={`${user.picture}`}
                        width="100%"
                        height="100%"
                        style={{ borderRadius: '50%' }}
                      />
                    )
                  : null}
              </Box>
              <Typography className="user-details-lable">{`Name: ${user.name}`}</Typography>
              <Typography className="user-details-lable">{`Given Name: ${user.given_name}`}</Typography>
              <Typography className="user-details-lable">{`Family Name: ${user.family_name}`}</Typography>
              <Typography className="user-details-lable">{`Nickname: ${user.nickname}`}</Typography>
              <Typography className="user-details-lable">{`Last Login Date: ${formattedLastLoginDate}`}</Typography>
              <Typography className="user-details-lable">{`Login Counts: ${user.logins_count}`}</Typography>
              <Typography className="user-details-lable">{`Locale: ${user.locale}`}</Typography>
            </CardContent>
          </Card>
        </Box>

        {/* <pre>
          {JSON.stringify(user, null, '  ')}
        </pre> */}
      </Box>
      <ConfirmDeleteDialog name={user.name} userId={user.user_id} open={deleteOpen} onClose={() => setDeleteOpen(false)} />
    </>
  )
}

function PageBody() {
  const dispatch = useDispatch()
  const { userId } = useParams<{ userId: string }>()
  const requestingUserState = useSelector((state: ReducerRootState) => state.userManagement.requestingUserState)
  const [firstRun, setFirstRun] = useState(true)

  useEffect(() => {
    dispatch(fetchUser(userId))
    setFirstRun(false)

    return () => { dispatch(clearSelectedUser(userId)) }
  }, [])

  if (firstRun) {
    return <LoadingView />
  }

  switch (requestingUserState) {
    case UserRequestState.NONE:
    case UserRequestState.REQUESTING:
      return <LoadingView />
    case UserRequestState.FULFILLED:
      return <FulfilledView />
    case UserRequestState.ERROR:
      return <Typography variant="h4">Failed to load user info</Typography>
    default:
      throw new Error('Invalid state')
  }
}

export default function UserManagementPage() {
  return (
    <PageLayout>
      <PageBody />
    </PageLayout>
  )
}
