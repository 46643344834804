export const types = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
}

export function login(accessToken: string) {
  return {
    type: types.LOGIN,
    accessToken,
  }
}

export function localLogout() {
  return {
    type: types.LOGOUT,
  }
}
