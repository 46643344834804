import AccountState from '../../objects/accountState'
import { types } from './actions'

const initialState: AccountState = {
  accessToken: '',
  loggedIn: false,
}

export default function accountReducer(state: AccountState = initialState, action): AccountState {
  switch (action.type) {
    case types.LOGIN:
      return {
        ...state, loggedIn: true, accessToken: action.accessToken,
      }
    case types.LOGOUT:
      return {
        ...state, loggedIn: false, accessToken: '',
      }

    default:
      return state
  }
}
