import {
  Box, Typography, TableContainer,
  TableRow, TableCell, TableBody, Table, TableHead, Card, CardContent, CardHeader,

} from '@mui/material'
import danger from '../../../assets/face-detection.png'
import noVpnRisk from '../../../assets/no-vpn-risk.png'
import { idScanAttemptsSeparated, SessionRiskEvent } from '../../../objects/sessionResultsDto'
import getCustomiseString from '../../../utils/CustomiseUtils/customiseString'

interface SessionRisksProps {
  sessionIPRisks: any
  sessionIdScanAttempts: idScanAttemptsSeparated | undefined
  sessionRiskEvents: SessionRiskEvent[] | undefined

}
function SessionRisksTab(props: SessionRisksProps) {
  const { sessionIPRisks, sessionIdScanAttempts, sessionRiskEvents } = props
  return (
    <>
      <Box mt={2}>
        {(typeof sessionIPRisks === 'undefined')
        && (
          <Box mt={6}>
            <Typography variant="body1">
              There are no IP Risks info for this session
            </Typography>
          </Box>
        )}
        {(sessionIPRisks) && (
          <Card className="container">

            {sessionIPRisks?.map(risk => (
              <Box>

                { risk.provider === 'IPQS' ? (
                  <>
                    <Box>

                      <CardHeader title="Network Risks" style={{ display: 'flex', justifyContent: 'flex-start' }} />
                      <CardContent className="card-content">
                        <CardHeader style={{ display: 'flex', justifyContent: 'flex-start' }} />
                        <Box mb={2}>
                          <Typography variant="body1">{`IP Address: ${getCustomiseString(risk?.ipAddress)} `}</Typography>
                          <Typography variant="body1">{`VPN Status: ${getCustomiseString(risk?.vpn)} `}</Typography>
                          <Typography variant="body1">{`TOR Status: ${getCustomiseString(risk.tor)}`}</Typography>
                          <Typography variant="body1">{`Proxy Status: ${getCustomiseString(risk.proxy)}`}</Typography>
                          <Typography variant="body1">{`ISP Name: ${getCustomiseString(risk.ISP)}`}</Typography>
                          <Typography variant="body1">
                            {`IP Address Fraud Risk Rating:
                          ${getCustomiseString(risk.fraud_score)} `}

                          </Typography>
                        </Box>
                      </CardContent>

                    </Box>
                    {/* <Box mb={4}>

                    <CardHeader title="IP address fraud risk score" style={{ display: 'flex', justifyContent: 'flex-start' }} />
                    <CardContent className="card-content">
                      <CardHeader style={{ display: 'flex', justifyContent: 'flex-start' }} />
                      <Box mb={2}>
                        <Typography variant="body1">{`Fraud Score: ${risk?.fraud_score} `}</Typography>
                      </Box>
                    </CardContent>

                  </Box> */}

                  </>
                ) : <></>}
              </Box>

            ))}
          </Card>
        )}
      </Box>
      <Box>
        {sessionIdScanAttempts?.front && sessionIdScanAttempts?.front.length > 0 && sessionIdScanAttempts.front.map((attempt, index) => (

          <Box mt={2} key={attempt.resultBlob}>
            {attempt.faceOnDocumentStatus === 'CANNOT_CONFIRM_ID_IS_AUTHENTIC'
              ? (
                  <Card className="container">
                    <CardContent className="card-content">
                      <CardHeader style={{ display: 'flex', justifyContent: 'flex-start' }} />
                      <Box mb={2}>
                        <Typography variant="h6">
                          <b>
                            Tampering Check: Frontside-Attempt
                            {' '}
                            {sessionIdScanAttempts.front.length - index}
                          </b>
                        </Typography>
                      </Box>
                      <Box className="attempt-layout">
                        <Typography variant="body1">
                          Anti-Tampering Check:
                        </Typography>
                        <Typography className="tampering-style" variant="body1">
                          {attempt.faceOnDocumentStatus.replace(/_/g, ' ')}
                        </Typography>
                      </Box>

                    </CardContent>
                  </Card>
                )
              : <></>}
          </Box>

        ))}
      </Box>

      <Box mt={2}>
        <Card className="container">

          { (sessionRiskEvents && sessionRiskEvents.length > 0 && sessionRiskEvents.map((risk, index) => (
            <Box>
              <CardHeader title="" />
              <CardContent className="card-content">
                <CardHeader style={{ display: 'flex', justifyContent: 'flex-start' }} />
                <Box>
                  <Typography variant="h6">
                    <b>
                      Risk
                      {' '}
                      {sessionRiskEvents.length - index}
                    </b>
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body1">{`Risk Count: ${risk.count} `}</Typography>
                  <Typography variant="body1">{`Risk Type: ${risk.riskType.replace(/_/g, ' ')}`}</Typography>
                  <Typography variant="body1">{`Risk Details: ${JSON.stringify(risk.rawInfo)}`}</Typography>
                </Box>
              </CardContent>

            </Box>
          )))}
        </Card>
      </Box>

    </>

  )
}

export default SessionRisksTab
