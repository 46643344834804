import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material'
import PageLayout from '../PageLayout'
import UserTable from './UserTable'
import AddUserDialog from './AddUserDialog'
import { fetchUsers } from '../../services/UserManagementService'
import ReducerRootState from '../../objects/reducerRootState'
import { UserRequestState } from '../../objects/userManagementState'
import LoadingView from '../LoadingView'
import Toolbar from './Toolbar'

function FulfilledView() {
  const [addUserOpen, setAddUserOpen] = useState(false)
  const users = useSelector((state: ReducerRootState) => state.userManagement.users)
  return (
    <>
      <Toolbar setAddUserOpen={setAddUserOpen} />
      <UserTable users={users} />
      <AddUserDialog open={addUserOpen} onClose={() => setAddUserOpen(false)} />
    </>
  )
}

function PageBody() {
  const dispatch = useDispatch()
  const requestingUsersState = useSelector((state: ReducerRootState) => state.userManagement.requestingUsersState)
  const [firstRun, setFirstRun] = useState(true)

  useEffect(() => {
    dispatch(fetchUsers())
    setFirstRun(false)
  }, [])

  if (firstRun) {
    return <LoadingView />
  }

  switch (requestingUsersState) {
    case UserRequestState.NONE:
    case UserRequestState.REQUESTING:
      return <LoadingView />
    case UserRequestState.FULFILLED:
      return <FulfilledView />
    case UserRequestState.ERROR:
      return <Typography variant="h4" style={{ color: 'white' }}>Failed to load users</Typography>
    default:
      throw new Error('Invalid state')
  }
}

export default function UsersManagementPage() {
  return (
    <PageLayout>
      <PageBody />
    </PageLayout>
  )
}
