import { AppState, Auth0Provider, User } from '@auth0/auth0-react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { StylesProvider } from '@mui/styles'
import { persistor, store, StoreRegistry } from '../../stores'
import { setupEnvironment } from '../../services/EnvironmentService'
import { setupTheme } from '../../services/ThemeService'
import { loadConfig } from '../../services/ConfigService'
import AppRouter, { navigatePtr } from './Routes'
import AuthTokenBroker from './AuthTokenBroker.tsx'

StoreRegistry.register(store)
setupEnvironment()
const { tenant } = StoreRegistry.store.getState().environment
const clientId = import.meta.env.VITE_CONSOLE_CLIENT_ID || ''
setupTheme(tenant)
loadConfig(tenant)

const onRedirectCallback = (appState?: AppState) => {
  // Clear out auth stuff from the URI
  window.history.replaceState({}, '', '/')
  // Navigate to the actual page
  // This is potentially racey, but we'd have to resolve the user's stuff on the first render for this to actually be a problem.
  // We could also just queue a microtask here, or just add a dumb timeout.
  if (appState?.returnTo) navigatePtr.navigate(appState.returnTo, { replace: true })
}

function App(): JSX.Element {
  return (
    <Auth0Provider
      domain="dev-1ihbc5u3.au.auth0.com"
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: 'https://brykid.brykgroup.ai/',
        scope: 'read:session_results read:users manage:users read:session_events read:session_risks',
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      <StylesProvider injectFirst>
        <Provider store={store}>
          <PersistGate
            loading={null}
            persistor={persistor}
          >
            <AuthTokenBroker />
            <AppRouter />
          </PersistGate>
        </Provider>
      </StylesProvider>
    </Auth0Provider>
  )
}

export default App
